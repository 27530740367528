import eventBus from "../views/controls/eventBus"
import { map, view, zoomToLayer } from './mapController';
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import SketchViewModel from "@arcgis/core/widgets/Sketch/SketchViewModel";
import Polyline from "@arcgis/core/geometry/Polyline";
import Polygon from "@arcgis/core/geometry/Polygon";
import Extent from "@arcgis/core/geometry/Extent";
import Graphic from "@arcgis/core/Graphic";
import { getJobFeatures, updateJobFeatures} from './jobController';
import { xyToLngLat, geographicToWebMercator } from "@arcgis/core/geometry/support/webMercatorUtils";

var jobFeatures;
var sketchVM;
var createStarted = false;
var shiftHeld;
var selectionMode;

const lineSymbolforPoint = {
  type: "simple-line",  // autocasts as new SimpleMarkerSymbol()
  color: "rgba(0,0,0,0)",
  width: 1.5,
  // Define a blue "x" marker at the beginning of the line
  marker: { // autocasts from LineSymbolMarker
     style: "circle",
     color: "cyan",
     placement: "begin"
  }
}

const basicLineSymbol = {
  type: "simple-line",  // autocasts as new SimpleMarkerSymbol()
  color: "cyan",
  width: 1.5
}

export var hasChanges = false;

export function setJobFeatures(features){
  jobFeatures = features
  hasChanges = false
}

export const reset = () => {
  if (sketchVM){
   
    selectionMode = null;
    sketchVM.complete();

    //delete graphics layer
    var id = "sketchLayer"
    var lyrExisting = map.findLayerById(id);

    if (lyrExisting){
      lyrExisting.removeAll();
    }

    hasChanges = false;
  }
 
  eventBus.dispatch("reset", { message: "reset" });
}

export const editMultiple = (mode) => {

  //Get in to polygon sketch mode
  selectionMode = mode
  var updatePolygon;
  var id = "sketchLayer"
  var sketchLayer;
  var lyrExisting = map.findLayerById(id);

  if (lyrExisting){
    sketchLayer = lyrExisting
    sketchLayer.removeAll();
  }
  else{
    sketchLayer = new GraphicsLayer({ title: id, id: id });
    map.add(sketchLayer);
  }
  
  sketchVM = createSketchVM(sketchLayer);

  sketchVM.on(["create"], (event) => {
    // update the filter every time the user finishes drawing the filtergeometry
    if (event.state == "complete") {
      createStarted = false;
      var sketchGeometry = event.graphic.geometry;
      
      //Copy features within polygon from feature layers
      queryLayers(sketchGeometry, sketchLayer).then(function(){
        if (sketchGeometry.type == "point"){
          //if query results has point, then remove any line
          let points = sketchLayer.graphics.filter(graphic =>{return graphic.tag == "point"});
          let lines = sketchLayer.graphics.filter(graphic =>{return graphic.tag == "polyline"});
          if (points.length > 0 && lines.length > 0){
            lines.forEach(feature =>{
              sketchLayer.remove(feature);
            })
          }
          
        }
      
        sketchVM.update(sketchLayer.graphics.items, {
          tool: "transform",
          enableRotation: true,
          enableScaling: false,
          preserveAspectRatio: true,
          toggleToolOnClick: true
        });
      })
    }
  });
  
  sketchVM.on(["update"], (event) => {
    //if (updatePolygon == null){
      updatePolygon = sketchVM.view.extent
    //}
   
    const eventInfo = event.toolEventInfo;
    
    // update the filter every time the user moves the filtergeometry
    if (
      event.toolEventInfo &&
      event.toolEventInfo.type.includes("stop")
    ) {
      //Update source layer
      var graphics = event.graphics;
      updateFeatures(graphics, updatePolygon).then(function(features){
        updateJobPolygon().then(function(jobPoly){
          let updatedFeatures = features.filter(function(f){
            return (f[0] != null)
          })
          let flattenFeatures = updatedFeatures.map(function(f){
            return f[0];
          })
          flattenFeatures.push(jobPoly);
          applyUpdateToJobFeatures(jobFeatures, flattenFeatures);
          hasChanges = true
        });
      })
    }
    else if (event.aborted){
      if (!createStarted){
        //clear graphics layer
        sketchLayer.removeAll();
        create();
      }
    }
    else if (event.state == "complete"){
      //if (!shiftHeld){
        sketchLayer.removeAll();
      //}
      create();
    }
  });

  if (sketchVM.state != "active"){
    if (selectionMode && selectionMode != "all"){
      //Turn on create tool:
      create();
    }
    else{
      if (selectionMode == "all"){
        //Select all features:
        //Copy features from feature layers
        queryLayers(null, sketchLayer).then(function(){
          updatePolygon = null
          sketchVM.update(sketchLayer.graphics.items, {
            tool: "transform",
            enableRotation: true,
            enableScaling: false,
            preserveAspectRatio: true,
            toggleToolOnClick: true
          });
          view.goTo(sketchLayer.graphics.items).then(function(){setTimeout(function(){view.zoom = view.zoom - 1}, 50)});
        })
      }
    }
  }
  else{
    sketchVM.complete()
  }
}

function create(){
  if (selectionMode && selectionMode != "all"){
      sketchVM.create(selectionMode);
      createStarted = true;
  }
}

function createSketchVM(sketchLayer){
  var sketch = new SketchViewModel({
    layer: sketchLayer,
    updateOnGraphicClick:false,
    view: view,
    polygonSymbol: {
      type: "simple-fill",
      style: "solid",
      color: [150, 150, 150, 0.2],
      outline: {
        color: [50, 50, 50],
        width: 2
      }
    },
    polylineSymbol: {
      type: "simple-line",  // autocasts as new SimpleMarkerSymbol()
      color: "rgba(0,0,0,0)",
      width: 1.5,
      // Define a blue "x" marker at the beginning of the line
      marker: { // autocasts from LineSymbolMarker
         style: "circle",
         color: "blue",
         placement: "begin"
      }
    },
    defaultCreateOptions: { mode: "click", hasZ: false }
  });
  return sketch;
}

export function updateJobPolygon(){
  //Get existing job polygon
  var polyLayer = map.layers.filter(function(layer){
    return layer.id == "f-jobpoly";
  });
  
  let layer = polyLayer.items[0];
  //Get job polygon
  return queryLayer(layer, null).then(function(features){
    return getJobExtent().then(function(extent){
      features[0].geometry = Polygon.fromExtent(extent);
      let params = {updateFeatures:features};
      return layer.applyEdits(params).then(function(){
        return features[0];
      });
    });
  });
}

async function getJobExtent(){
  var promises = []
  map.layers.forEach(layer => {
    if (layer.id.indexOf("f-") == 0 && layer.id != "f-jobpoly" && layer.id.indexOf("f-geofeature") < 0 && layer.id.indexOf("f-userLocation") < 0){
      promises.push(queryLayer(layer, null).then(function(features){
        return features;
      }))
    }
  });

  const featureLayers = await Promise.all(promises);
  var extent;
  featureLayers.forEach((features) => {
    features.forEach((feature) => {
      var gextent;
      if (feature.geometry.type == "point"){
        gextent = new Extent({xmax:feature.geometry.x + 0.00001, xmin:feature.geometry.x - 0.00001, ymax:feature.geometry.y + 0.00001, ymin:feature.geometry.y - 0.00001, spatialReference: { wkid: 4326 }});
      }
      else{
        gextent = feature.geometry.extent;
      }
      if (gextent){
        if (!extent) {
          extent = gextent;
        }
        if (!gextent.equals(extent)) { //this should only skip the first one
          extent = extent.union(gextent);
        }
      }
    });
  })
  
  return extent;
}


function updateFeatures(graphics, polygon){
  var promises = []
  map.layers.forEach(layer => {
    if (layer.id.indexOf("f-") == 0 && layer.id != "f-jobpoly" && layer.id.indexOf("f-geofeature") < 0 && layer.id.indexOf("f-userLocation") < 0){
      promises.push(queryLayer(layer, polygon).then(function(features){
        var updatedFeatures = []
        features.forEach(feature =>{
          //find the graphic
          var matchGraphics = graphics.filter(function(g){
            return g.attributes["uuid"] == feature.attributes["uuid"];
          })
          if (matchGraphics.length > 0){
            let graphic = matchGraphics[0];
            if (feature.geometry.type == "point"){
              //create the point geometry
              let linePaths = graphic.geometry.paths;
              const point = {
                type: "point", // autocasts as new Point()
                hasZ: false,
                hasM: true,
                spatialReference: { wkid: 3857 },
                x: linePaths[0][0][0],
                y: linePaths[0][0][1]
              };
              feature.geometry = point;
            }
            else{
              feature.geometry = graphic.geometry;
            }
            updatedFeatures.push(feature)
          }
        })
        let params = {updateFeatures:updatedFeatures};
        return layer.applyEdits(params).then(function(){
          return updatedFeatures
        })
      }))
    }
  })
  return Promise.all(promises)
}

export async function applyUpdateToFeatures(updatedFeatures){
  applyUpdateToJobFeatures(jobFeatures, updatedFeatures)

  return jobFeatures
}

function applyUpdateToJobFeatures(jobFeatures, features){
 
    features.forEach(function(f){
      var jobFeature = jobFeatures.filter(function(jf){
        return (f.attributes["uuid"] == jf.properties["uuid"]) || (f.attributes["UUID"] == jf.properties["uuid"])
      })
  
      if (jobFeature.length > 0){

        if (f.geometry.type == "point" && jobFeature[0].geometry.type == "Point"){
          //Convert x,y to lat long
          var LngLat
          if (f.geometry.x && f.geometry.y){
            LngLat = xyToLngLat(f.geometry.x,f.geometry.y);
          } else {
            LngLat = [f.geometry.longitude,f.geometry.latitude];
          }
          
          //update lat/lon
          if (jobFeature[0].geometry.coordinates != LngLat){
            jobFeature[0].properties["moved"] = "Yes"
            jobFeature[0].geometry.coordinates = LngLat 
          }
        }
        else if (f.geometry.type == "point" && jobFeature[0].geometry.type == "LineString"){
          //Convert x,y to lat long
          var LngLat
          if (f.geometry.x && f.geometry.y){
            LngLat = xyToLngLat(f.geometry.x,f.geometry.y);
          } else {
            LngLat = [f.geometry.longitude,f.geometry.latitude];
          }
          
          //update lat/lon
          var ar_coords = JSON.parse(jobFeature[0].properties["ar_coords"])
          var index
          ar_coords.forEach(function(ar_coord, i){
            if (ar_coord[0].toString() == f.attributes["Feature_AR_X-Coordinate"] && ar_coord[1].toString() == f.attributes["Feature_AR_Y-Coordinate"]){
              index = i
            }
          })
          if (index != null){
            jobFeature[0].geometry.coordinates[index][0] = LngLat[0]
            jobFeature[0].geometry.coordinates[index][1] = LngLat[1]
            jobFeature[0].properties["moved"] = "Yes"
          }
        }
        else if (f.geometry.type == "polyline"){
          //Line, convert each joint in the path
          
          var coords = []
          f.geometry.paths[0].forEach(function(segment){
              let LngLat = xyToLngLat(segment[0],segment[1]);
              coords.push(LngLat);
          })
          
          if (jobFeature[0].geometry.coordinates != coords){
            jobFeature[0].properties["moved"] = "Yes"
            jobFeature[0].geometry.coordinates = coords
          }
          
        }
        else if (f.geometry.type == "polygon"){
          //Polygon, convert each joint in the path
          var coords = []
          f.geometry.rings[0].forEach(function(segment){
              //let LngLat = xyToLngLat(segment[0],segment[1]);
              coords.push(segment);
          })
          
          if (jobFeature[0].geometry.coordinates != [coords]){
            jobFeature[0].properties["moved"] = "Yes"
            jobFeature[0].geometry.coordinates = [coords]
          }
          
        }
      }
    })
  
}

function queryLayers(sketchGeometry, sketchLayer){
  
  sketchLayer.removeAll();
  
  var promises = [];
  map.layers.forEach(layer => {
    if (layer.id.indexOf("f-") == 0 && layer.id != "f-jobpoly" && layer.id.indexOf("f-geofeature") < 0 && layer.id.indexOf("f-userLocation") < 0){

        promises.push(queryLayer(layer, sketchGeometry).then(function(features){
          features.forEach(feature =>{
            if (feature.geometry.type == "point"){
              var geometry;
              if (feature.geometry.spatialReference.wkid == "4326"){
                geometry = geographicToWebMercator(feature.geometry)
              }
              else{
                geometry = feature.geometry;
              }
              
              //Create line segment out of point
              let paths = [
                [  // first path
                 [geometry.x, geometry.y],
                 [geometry.x + 0.2, geometry.y + 0.2],
                 [geometry.x - 0.2, geometry.y - 0.2]
                ]
               ];
               
               let line = new Polyline({
                 hasZ: false,
                 hasM: true,
                 paths: paths,
                 spatialReference: { wkid: 3857 }
               });
              const polylineGraphic = new Graphic({
                geometry: line,
                symbol: lineSymbolforPoint,
                attributes: feature.attributes,
              });
              polylineGraphic.tag = "point"
              sketchLayer.add(polylineGraphic)
            }
            else{
              feature.symbol = basicLineSymbol
              feature.tag = "polyline"
              sketchLayer.add(feature)
            }
          })
        }))
    }
  });
  
  return Promise.all(promises)
  
}

function queryLayer(featureLayer, sketchGeometry) {
  try{
    const query = featureLayer.createQuery();
    if (sketchGeometry == null){
      //use empty query to get all features
    }
    else{
      if (sketchGeometry.type == "point"){
        //Create a box around the point
        let paths = [
          [  // first path
          [sketchGeometry.x - 0.5,sketchGeometry.y - 0.5],
          [sketchGeometry.x + 0.5,sketchGeometry.y + 0.5]
          ]
        ];
        let line = new Polyline({
          hasZ: false,
          hasM: true,
          paths: paths,
          spatialReference: { wkid: 3857 }
        });
        // Get a sum of age groups for census tracts that intersect the polygon buffer
        
        query.geometry = line.extent;
      }
      else{
        query.geometry = sketchGeometry;
      }
    }
    // Query the features on the client using FeatureLayerView.queryFeatures
    return featureLayer.queryFeatures(query).then(function(results) {
      // Return information, seperated by gender
      return results.features;
    })
    .catch(function(error) {
      console.log(error);
    });
  }
  catch(ex){
    alert("query error")
    let e = ex;
  }
  
}



