import React, { useEffect, useState } from 'react';
import {Container, Navbar, Nav, ListGroup, Badge, Row, Col, Button, Toast, Form } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLink, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { faTrash, faCircleMinus, faCirclePlus, faMinus, faCircle, faPlus, faCopy, faFloppyDisk, faFileImport, faCircleInfo} from '@fortawesome/free-solid-svg-icons'
import ConfigFieldList from './configFieldList';
import ConfirmDialog from '../controls/confirmDialog';
import CopyBubble from '../controls/copyBubble';
import { getHashParam, setHash} from '../../controllers/hashController';
import ExternalLinkEditor from './externalLinkEditor';
import EsriServiceDialog from '../controls/esriServiceDialog';
import { getEsriFeatureConfig, constructResultUrl, constructVisionLink } from '../../controllers/configController';
import { getFeatureLayerFields, addVisionLinkToWebLayer, getWebMap, updateWebMap, getFeatureLayers} from '../../controllers/esriAuthController';
import { Link } from 'react-router-dom';

function ConfigFeatureList(props) {
  
  const [showLogin, setShowLogin] = useState(false);
  const [dialogMessage, setDialogMessage] = useState(null);
  const [message, setMessage] = useState(null);
  const [featureClass, setFeatureClass] = useState(null);
  const [featureClassHighlight, setFeatureClassHighlight] = useState(null);
  const [featureClassIndex, setFeatureClassIndex] = useState(null);
  const [featureClasses, setFeatureClasses] = useState([]); 
  const [clipboard, setClipboard] = useState(props.clipboard);
  const [showExternalLink, setShowExternalLink] = useState(false);
  const [esriMode, setEsriMode] = useState(false);

  const clearChecked = () => {
    featureClasses.forEach(function(fc){
      delete fc.checked;
    })
  };

  const validateData = () => {

    try{
       //"fixedDistanceInformation":{"maximumDistance":"", "fallbackDistance":""}
      //These are numbers, delete empty string if any (they are needed for the screen to work when no numbers)
      featureClasses.forEach(function(fc){

        if (fc.fixedDistanceInformation){
            if (!parseFloat(fc.fixedDistanceInformation.maximumDistance)){
              delete fc.fixedDistanceInformation.maximumDistance;
            }
            else{
              fc.fixedDistanceInformation.maximumDistance = parseFloat(fc.fixedDistanceInformation.maximumDistance);
            }
    
            if (!parseFloat(fc.fixedDistanceInformation.fallbackDistance)){
              delete fc.fixedDistanceInformation.fallbackDistance;
            }
            else{
              fc.fixedDistanceInformation.fallbackDistance = parseFloat(fc.fixedDistanceInformation.fallbackDistance);
            }

            if (!(fc.fixedDistanceInformation.maximumDistance || fc.fixedDistanceInformation.fallbackDistance)){
              //Clean up empty fixedDistanceInformation
              delete fc.fixedDistanceInformation;
            }
        }

        //Clean up empty mlModelMapping
        if (fc.mlModelMapping){
          if (!fc.mlModelMapping.featureClass){
            delete fc.mlModelMapping;
          }
        }

        if (fc.auxiliaryModels != null && fc.auxiliaryModels.length > 0){
          for (var i=fc.auxiliaryModels.length-1;i>=0;i--){
            var auxiliaryModel = fc.auxiliaryModels[i];
            if (!auxiliaryModel.modelId){
              fc.auxiliaryModels.splice(i, 1);
            }
          }
          if (fc.auxiliaryModels != null && fc.auxiliaryModels.length > 0){
            delete fc.auxiliaryModel
          }
        }

        if (fc.featureDescriptionSuffix == ""){
          delete fc.featureDescriptionSuffix;
        }
        
        if (fc.featureIdAttribute == ""){
          delete fc.featureIdAttribute;
        }

        if (fc.featureClassDisplay == ""){
          delete fc.featureClassDisplay;
        }
        
        if (fc.modelSubtypes != null && fc.modelSubtypes.length > 0){
          for (var i=fc.modelSubtypes.length-1;i>=0;i--){
            var modelSubtype = fc.modelSubtypes[i];
            if (modelSubtype.name.length == 0){
              fc.modelSubtypes.splice(i, 1);
            }
          }
        }
        
        if (fc.modelSubtypes == null || (fc.modelSubtypes != null && fc.modelSubtypes.length == 0)){
          delete fc.modelSubtypes;
        }

        fc.attributes.forEach(function(field){
          if (!field.voiceSubstitutions || Object.keys(field.voiceSubstitutions).length === 0 ){
            delete field.voiceSubstitutions;
          }
          if (!field.patterns || field.patterns == ""){
            field.patterns = [];
          } else {
            field.patterns.forEach(function(pattern){
              if ((pattern.positiveValidationStrings && pattern.positiveValidationStrings.length == 0) || pattern.positiveValidationStrings == null){
                delete pattern.positiveValidationStrings
              }
              if ((pattern.negativeValidationStrings && pattern.negativeValidationStrings.length == 0) || pattern.negativeValidationStrings == null){
                delete pattern.negativeValidationStrings
              }
            });
          }

          if (!field.subtypePatterns || field.subtypePatterns == ""){
            field.subtypePatterns = [];
          } else {
            field.subtypePatterns.forEach(function(pattern){
              if ((pattern.positiveValidationStrings && pattern.positiveValidationStrings.length == 0) || pattern.positiveValidationStrings == null){
                delete pattern.positiveValidationStrings
              }
              if ((pattern.negativeValidationStrings && pattern.negativeValidationStrings.length == 0) || pattern.negativeValidationStrings == null){
                delete pattern.negativeValidationStrings
              }
            });
          }

          //Delete empty function
          if (field.default == ""){
            delete field.default;
          }
          if (field.function == ""){
            delete field.function;
          }
          if (field.pickList == ""){
            delete field.pickList;
          }
          if (field.displayName == ""){
            delete field.displayName;
          }
          if (field.keyPattern == ""){
            delete field.keyPattern;
          }
          if (field.valueLocation == ""){
            delete field.valueLocation;
          }
        })
        
        if (fc.externalLink == ""){
          delete fc.externalLink;
        }
      })
    }
    catch(e){
      
    }
  };

  const handleSaveConfig = () => {
    clearChecked();
    validateData();
    props.jobType.featureDefinitions = featureClasses;
    props.onSave();
  };

  const handleBackToJobTypes = (e) => {
    clearChecked();
    validateData();
    props.jobType.featureDefinitions = featureClasses; //Parent shall know the changes
    props.onBackToJobTypes();
  };

  const handleBackToFeatures = (e) => {
    setFeatureClass(null);
    setHash(null,null, 2);
  };

  const handleCheckChanged = (featureClass, e) => {
    var clonedFeatureClasses = [...featureClasses]//Clone the array before update, so that we get proper state change
    var selectedFeatureClass = clonedFeatureClasses.filter(function(fc){
      return fc.featureClass == featureClass.featureClass;
    })[0];
    if (selectedFeatureClass){
        selectedFeatureClass.checked = e.currentTarget.checked;
        setFeatureClasses(clonedFeatureClasses);
    }
    
  };

  const handleSelect = (fc, i, e) => {
    setFeatureClassHighlight(fc);
    setFeatureClassIndex(i);
    setFeatureClass(fc);
    setHash(fc,"featureClass", 2);
  };

  const handleHighlight = (fc, i, e) => {
    setFeatureClassHighlight(fc);
    setFeatureClassIndex(i);
  };

  const handleDeleteConfirm = (e) => {
    setDialogMessage(null);
   
    //delete jobs from UI
    var leftFeatureClasses = featureClasses.filter(function(fc){
      return !fc.checked
    })
    setFeatureClasses([...leftFeatureClasses]);
   
    //Check to see if currently selected field is still in the list
    var currentFeatureClasses = leftFeatureClasses.filter(function(f){
      return f.featureClass == featureClassHighlight.featureClass;
    })
    if (currentFeatureClasses.length == 0){
      if (leftFeatureClasses.length > 0){
        setFeatureClassHighlight(leftFeatureClasses[0]);
        setFeatureClassIndex(0);
      }
      else{
        setFeatureClassHighlight(null);
        setFeatureClassIndex(0);
      }
    }
  }

  const handleDeleteCancel = (e) => {
    setDialogMessage(null);
  }

  const handleEsriLoginCancel = (e) => {
   setShowLogin(false);
  }

  const handleEsriLoginConfirm = (params) => {

    setShowLogin(false);
    if (params.mode == "import"){
      setMessage("Importing feature config...");
      if (params.layer_id){
        getFeatureConfig(params)
      } else if (params.layers){
        getFeaturesConfig(params)
      }
    } else {
      //update
      updateFeaturesConfig(params)
    }
  }

  const getFeatureConfig = (params) => {
    return getEsriFeatureConfig(props.accessToken, params).then(function(results){
      if (results.status == 200){
         //Add results to feature list
        var newFeatureClasses = [...featureClasses]; //clone to update
        let newfeatureClass = results.data
        //Set field order
        newfeatureClass.attributes.forEach(function(f,i){
          f.order = i+1
        })

        if (newfeatureClass.geometryType == "") {
          newfeatureClass.geometryType = "Point";
        }
        //Add external link...
        if (params.externalLinkType && params.externalLinkType.length > 0 && params.externalLinkType != "none"){
          let externalUrl = constructResultUrl(params.externalLinkType, params.direction, params.itemId, true, false, params.feature_service_url + "/" + params.layer_id, params.externalfields);
          newfeatureClass.externalLink = externalUrl;
          props.jobType.jobMode = "Non AR";
        }
        
        newFeatureClasses.push(newfeatureClass);
        if (!props.jobType.featureDefinitions){
          props.jobType.featureDefinitions = [];
        }
        props.jobType.featureDefinitions.push(newfeatureClass);
        
        setFeatureClasses(newFeatureClasses);
        setFeatureClassHighlight(newfeatureClass);
        setFeatureClassIndex(newFeatureClasses.length-1);
        setMessage(null);
      }
      else{
        setMessage("Failed to import feature: " + results.response.data.message);
        setTimeout(function(){
          setMessage(null);
        }, 3000)
      }
    })
  }

  const getFeaturesConfig = (params) => {
    var newFeatureClasses = [...featureClasses]; //clone to update
    var promises = []
    params.layers.forEach(function(lyr){
      let localParams = {...params}
      localParams.layer_id = lyr.id
      promises.push(fetchFeatureConfig(localParams, newFeatureClasses))
    })
    return Promise.all(promises).then(function(result){
        setFeatureClasses(newFeatureClasses);
        setFeatureClassHighlight(newFeatureClasses[newFeatureClasses.length-1]);
        setFeatureClassIndex(newFeatureClasses.length-1);
        setMessage(null);
    })
  }

  const fetchFeatureConfig = (localParams, newFeatureClasses) => {
    return getFeatureLayerFields(localParams.feature_service_url, localParams.layer_id).then(function(validFields){
      localParams.fields = validFields.map(function(f){
        return f.name;
      });
      localParams.externalfields = validFields.map(function(f){
        f.checked = true;
        return f
      });
     
      return getEsriFeatureConfig(props.accessToken, localParams).then(function(results){
        if (results.status == 200){
           //Add results to feature list
          let newfeatureClass = results.data

          //Set field order
          newfeatureClass.attributes.forEach(function(f,i){
            f.order = i+1
          })

          if (newfeatureClass.geometryType == "") {
            newfeatureClass.geometryType = "Point";
          }
          //Add external link...
          if (localParams.externalLinkType && localParams.externalLinkType.length > 0 && localParams.externalLinkType != "none"){
            let externalUrl = constructResultUrl(localParams.externalLinkType, localParams.direction, localParams.itemId, true, false, localParams.feature_service_url + "/" + localParams.layer_id, localParams.externalfields);
            newfeatureClass.externalLink = externalUrl;
            props.jobType.jobMode = "Non AR";
          }
          
          newFeatureClasses.push(newfeatureClass);
          if (!props.jobType.featureDefinitions){
            props.jobType.featureDefinitions = [];
          }
          props.jobType.featureDefinitions.push(results);
          return true
        }
        else{
          return results.response.data.message
        }
      })
    })
  }


  const updateFeaturesConfig = async (params) => {
    var clonedFeatureClasses = [...featureClasses]//Clone the array before update, so that we get proper state change
    var webMapIds = [params.itemId]
    if (params.itemId == "all"){
      webMapIds = params.itemIds
    }
    webMapIds.forEach(async function(itemId){
      var webMap = await getWebMap(itemId);
      if (webMap != null){
        //Get feature service url from web map
      var featureServiceUrl;
      if (webMap.operationalLayers[0].layerType == "GroupLayer"){
        featureServiceUrl = webMap.operationalLayers[0].layers[0].url
      } else {
        featureServiceUrl = webMap.operationalLayers[0].url
      }
      //Remove the layer id
      featureServiceUrl = featureServiceUrl.substring(0,featureServiceUrl.lastIndexOf("/"))

      getFeatureLayers(featureServiceUrl).then(async (layers) => {
        await Promise.all(clonedFeatureClasses.map(async function(fc){
          //Get external link to replace
          if (fc.externalLink){
            //layer id and fields:
            //layer id will be coming from params, need to match what is in the config by name
            //fields will be fields from the new service, with existing ones checked
            let serviceLayer = layers.filter(function(lyr){
              return lyr.name == fc.featureClass;
            })[0]
    
            let validFields = await getFeatureLayerFields(featureServiceUrl, serviceLayer.id)

            var fields = [...fc.attributes].map(function(f,i){
              f.checked = false;
              return f
            });

            fc.attributes = updateSortOrder(fc.attributes, validFields)

            var fields = setSelectedFields(fields, fc.externalLink);
    
            //get new external link
            let externalUrl = constructResultUrl(params.externalLinkType, params.direction, itemId, true, false, featureServiceUrl + "/" + serviceLayer.id, fields);
            fc.externalLink = externalUrl;
    
            //Add or update external link in web map layer
            let visionLink = constructVisionLink(params.externalLinkType, params.direction, props.jobType.jobName, fc.featureClass, fields, false, itemId, featureServiceUrl + "/" + serviceLayer.id);
            webMap = await addVisionLinkToWebLayer(webMap, fc.featureClass, visionLink)
          }
        }));
        let result = await updateWebMap(itemId, webMap);
        if (result.status && result.status == 200){
          setMessage("Vision links added to web map!");
            setTimeout(function(){
              setMessage(null);
            }, 3000)
        } else {
          if (result.response) {
            setMessage("Failed to add Vision link to web map: " + result.response.data.message);
          } else {
            setMessage("Failed to add Vision link to web map: " + result);
          }
          
          setTimeout(function(){
            setMessage(null);
          }, 3000)
        }
      })
  
      setFeatureClasses(clonedFeatureClasses);
      }
      
    });
  }

  function updateSortOrder(array1, array2) {
    // Create a lookup map for quick access to indices in array2
    const orderMap = new Map(array2.map((item, index) => [item.name, index]));
  
    // Update sortOrder for matching items and collect unmatched items
    const unmatched = [];
    array1.forEach((item) => {
      if (orderMap.has(item.name)) {
        item.order = orderMap.get(item.name);
      } else {
        unmatched.push(item);
      }
    });
  
    // Optionally handle unmatched items (e.g., move to the end with default sortOrder)
    unmatched.forEach((item, index) => {
      item.order = array2.length + index; // Move unmatched items to the end
    });
  
    // Sort array1 by the updated sortOrder
    array1.sort((a, b) => a.order - b.order);
  
    return array1;
  }

  function getQueryParams(url) {
    const paramArr = url.slice(url.indexOf('?') + 1).split('&');
    const params = {};
    paramArr.map(param => {
        const [key, val] = param.split('=');
        params[key] = decodeURIComponent(val);
    })
    return params;
  }

  const setSelectedFields = (fields, externalLink) => {
    let params = getQueryParams(externalLink);
      if (params["featureAttributes"]){
          var selectedAttributes = JSON.parse(params["featureAttributes"]);
          for (var selAttr in selectedAttributes) {
            var matchingAttrs = fields.filter(function(a){
              return a.name.toLowerCase().replace(" ","_") == selAttr.toLowerCase();
            })
            if (matchingAttrs.length > 0){
              matchingAttrs[0].checked = true
            }
          }
      }
      else{
        //?field:height={Height}
        for (const key in params){
          if (key.indexOf("field:")>=0){
            var matchingAttrs = fields.filter(function(a){
              let name = key.replace("field:","");
              return a.name.toLowerCase().replace(" ","_") == name;
            })
            if (matchingAttrs.length > 0){
              matchingAttrs[0].checked = true
            }
          }
        };
      }
      return fields
  }

  const handleAdd = () => {
   //add empty feature class
    var newfeatureClass = {
      "featureClass":"",
      "geometryType":"Point",
      "arColor":"#ffff00",
      "featureDescriptionSuffix":"",
      "featureIdAttribute":"",
      "fixedDistanceInformation":{"maximumDistance":"", "fallbackDistance":""},
      "attributes":[],
      "msList":[]}
     var newFeatureClasses = [...featureClasses]; //clone to update
     newFeatureClasses.push(newfeatureClass);
     if (!props.jobType.featureDefinitions){
      props.jobType.featureDefinitions = []
     }
     props.jobType.featureDefinitions.push(newfeatureClass);

     setFeatureClasses(newFeatureClasses);
     setFeatureClassHighlight(newfeatureClass);
     setFeatureClassIndex(newFeatureClasses.length-1);

   
  }

  const handleDelete = () => {
    //delete jobs
    var selectedFeatureClasses = featureClasses.filter(function(fc){
      return fc.checked;
    });

    if (selectedFeatureClasses.length > 0){
      var deleteMsg;
    
      if (selectedFeatureClasses.length == 1){
        deleteMsg = "Delete " + selectedFeatureClasses[0].featureClass + "?";
      }
      else{
        deleteMsg = "Delete " + selectedFeatureClasses.length + " feature classes?";
      }
      setDialogMessage(deleteMsg);
    }
  }

  const handleCopy = () => {
    var selectedFeatureClasses = featureClasses.filter(function(j){
      return j.checked;
    })
   
    if (selectedFeatureClasses.length > 0){
      //Now copy the selected items
      var copyMsg;
      if (selectedFeatureClasses.length == 1){
        copyMsg = props.jobType.jobName + ":" + selectedFeatureClasses[0].featureClass + " copied!";
      }
      else{
        copyMsg = selectedFeatureClasses.length + " feature classes copied!";
      }

      props.clipboard[0] = {message:copyMsg, data:selectedFeatureClasses, type:"featureClass"}; //This is the global clipboard
      setClipboard([{message:copyMsg, data:selectedFeatureClasses, type:"featureClass"}]);

    }
  }

  const handlePaste = (clipboard) => {
    //Paste copied data
    var copiedItems = JSON.parse(JSON.stringify(clipboard[0].data)); //deep copy
    copiedItems.forEach(function(item){
      //check counts of item 
      var existing = featureClasses.filter(function(j){
        return j.featureClass.indexOf(item.featureClass) >= 0;
      })

      if (existing.length > 0){
        item.featureClass = item.featureClass + " " + (existing.length.toString());
      }

      item.checked = false;
    });

    var newFeatureClasses = featureClasses.concat(copiedItems); 
    
    setFeatureClasses(newFeatureClasses);
    props.clipboard[0] = null;
    setClipboard([]);

    //Select paste item if it is the first one
    if (newFeatureClasses.length == 1){
      handleHighlight(newFeatureClasses[0],0);
    }
  }

  const handleCopyCancel = (clipboard) => {
   props.clipboard[0] = null;
   setClipboard([]);
  }

  const handleImport = () => {
    setEsriMode("import");
    setShowLogin(true);
  }

  const handleUpdate = () => {
    setEsriMode("update");
    setShowLogin(true);
  }

  const handleFeatureClassTask = (key, e) => {
    switch(key){
      case "delete":
        handleDelete();
        break;

        case "save":
          handleSaveConfig();
          break;
        
        case "add":
          handleAdd();
          break;

        case "copy":
          handleCopy();
          break;

        case "import":
          handleImport();
          break;

        case "update":
          handleUpdate();
          break;
  
      default:
    }
  };

  const handleAddModelSubtype = (e) => {
   
    //Find current feature class in the array to update
    var currentFC = featureClasses[featureClassIndex]; //Need to access by featureClassIndex so that changing feature class name won't cause issu

    if (currentFC.modelSubtypes == null){
      currentFC.modelSubtypes = [];
    }
    currentFC.modelSubtypes.push({name:"", value:""});
    
    //Update current feature class as well
    if (featureClassHighlight){
      var newfeatureClass = {...currentFC}; //Clone it so that it will trigger state change
      setFeatureClassHighlight(newfeatureClass);
    }

    if (featureClass){
      setFeatureClass(newfeatureClass);
    }
  };

  const handleDeleteModelSubtype = (i, e) => {
   
    //Find current feature class in the array to update
    var currentFC = featureClasses[featureClassIndex]; //Need to access by featureClassIndex so that changing feature class name won't cause issue

    currentFC.modelSubtypes.splice(i,1);
    
    //Update current feature class as well
    if (featureClassHighlight){
      var newfeatureClass = {...currentFC}; //Clone it so that it will trigger state change
      setFeatureClassHighlight(newfeatureClass);
    }

    if (featureClass){
      setFeatureClass(newfeatureClass);
    }
  };

  const handleAddAuxiliaryModel = (e) => {
   
    //Find current feature class in the array to update
    var currentFC = featureClasses[featureClassIndex]; //Need to access by featureClassIndex so that changing feature class name won't cause issu

    if (currentFC.auxiliaryModels == null){
      currentFC.auxiliaryModels = [];
    }
    currentFC.auxiliaryModels.push({modelId:"", modelMode:""});
    
    //Update current feature class as well
    if (featureClassHighlight){
      var newfeatureClass = {...currentFC}; //Clone it so that it will trigger state change
      setFeatureClassHighlight(newfeatureClass);
    }

    if (featureClass){
      setFeatureClass(newfeatureClass);
    }
  };

  const handleDeleteAuxiliaryModel = (i, e) => {
   
    //Find current feature class in the array to update
    var currentFC = featureClasses[featureClassIndex]; //Need to access by featureClassIndex so that changing feature class name won't cause issue

    currentFC.auxiliaryModels.splice(i,1);
    
    //Update current feature class as well
    if (featureClassHighlight){
      var newfeatureClass = {...currentFC}; //Clone it so that it will trigger state change
      setFeatureClassHighlight(newfeatureClass);
    }

    if (featureClass){
      setFeatureClass(newfeatureClass);
    }
  };

  function modelSubtypeUpdate(i, e){
    
    //Find current feature class in the array to update
    var currentFC = featureClasses[featureClassIndex];

    //Find the current voice substitution to update, first check to see if it is name or value
    if (e.currentTarget.name.indexOf("name_")>=0){
      currentFC.modelSubtypes[i].name = e.currentTarget.value
    }
    else{
      //value
      if (e.currentTarget.value.indexOf(",")>0){
        currentFC.modelSubtypes[i].value = e.currentTarget.value.split(",");
      }
      else{
        currentFC.modelSubtypes[i].value = [e.currentTarget.value];
      }
      
    }
    
    //Update current feature class as well
    if (featureClassHighlight){
      var newfeatureClass = {...currentFC}; //Clone it so that it will trigger state change
      setFeatureClassHighlight(newfeatureClass);
    }
    if (featureClass){
      setFeatureClass(newfeatureClass);
    }

   }

   function auxiliaryModelUpdate(i, e){
    
    //Find current feature class in the array to update
    var currentFC = featureClasses[featureClassIndex];

    currentFC.auxiliaryModels[i][e.currentTarget.name] = e.currentTarget.value
    if (e.currentTarget.name == "modelId" && currentFC.auxiliaryModels[i]["modelMode"] == ""){
      currentFC.auxiliaryModels[i]["modelMode"] = "attributeFromObjectDetection"
    }
    //Update current feature class as well
    if (featureClassHighlight){
      var newfeatureClass = {...currentFC}; //Clone it so that it will trigger state change
      setFeatureClassHighlight(newfeatureClass);
    }
    if (featureClass){
      setFeatureClass(newfeatureClass);
    }
   }

   function handleExternalLink(){
    setShowExternalLink(true);
   }

   function handleExternalLinkConfirm(resultingUrl){
    var currentFC = featureClasses[featureClassIndex];
    currentFC["externalLink"] = resultingUrl;
    var newfeatureClass = {...currentFC}; //Clone it so that it will trigger state change
    setFeatureClassHighlight(newfeatureClass);
    if (featureClass){
      setFeatureClass(newfeatureClass);
    }
    setShowExternalLink(false);
   }

   function handleExternalLinkClose(){
    setShowExternalLink(false);
   }

  function fieldUpdate(e){
    
    //Find current featureclass in the array to update
    var currentFC = featureClasses[featureClassIndex]; //Need to access by featureClassIndex so that changing feature class name won't cause issue

    //Check to see if fieldname is multi level (e.g. fixedDistanceInformation.fallbackDistance)
    var fields = [e.currentTarget.name];
    if (e.currentTarget.name.indexOf(".")>0){
      fields = e.currentTarget.name.split(".");
    }

    //If it is multi level, need to access it by the property hierarchy
    if (fields.length == 1){
      currentFC[fields[0]] = e.currentTarget.value;
    }
    else{
      //multi level
      currentFC[fields[0]][fields[1]] = e.currentTarget.value;
    }
    
    //Update current feature class as well
    if (featureClassHighlight){
      var newfeatureClass = {...currentFC}; //Clone it so that it will trigger state change
      setFeatureClassHighlight(newfeatureClass);
    }

    if (featureClass){
      setFeatureClass(newfeatureClass);
    }
    
   }

   function fieldCheckUpdate(e){
    //Find current featureclass in the array to update
    var currentFC = featureClasses[featureClassIndex]; //Need to access by featureClassIndex so that changing feature class name won't cause issue
    
     if (e.currentTarget.value != ""){
        var value = e.currentTarget.checked;
        if (value == true){
          value = true
        }
        else{
          value = false
        }
        currentFC[e.currentTarget.name] = value;
     }

      //Update current feature class as well
    if (featureClassHighlight){
      var newfeatureClass = {...currentFC}; //Clone it so that it will trigger state change
      setFeatureClassHighlight(newfeatureClass);
    }

    if (featureClass){
      setFeatureClass(newfeatureClass);
    }
    
   }
  useEffect(() => {
    if (props.jobType){
     function sort_by_key(array, key)
       {
         //Sort desc
         return array.sort(function(a, b)
         {
           var x = a[key]; var y = b[key];
           return ((x < y) ? -1 : ((x > y) ? 1 : 0));
         });
       }
 
       if (props.jobType.featureDefinitions){
        var fcs = sort_by_key(props.jobType.featureDefinitions,"featureClass")
         
        setFeatureClasses(fcs);
        if (fcs.length > 0){
          //If there is url parameters, select it
          var fcName = getHashParam(window.location.hash, 2);
          if (fcName){
            //find jobType object
            var selectedFCIndex = fcs.findIndex(function(fc){
              return fc.featureClass == fcName;
            });
            if (selectedFCIndex >= 0){
              handleSelect(fcs[selectedFCIndex], selectedFCIndex);
            }
            else{
              handleHighlight(fcs[0],0);
            }
          }
          else{
            handleHighlight(fcs[0],0);
          }
        }
       }
    }
       
   },[props.jobType]);

   function getFeatureType(fc){
    if (fc.geometryType == "Point"){
        return (<FontAwesomeIcon icon={faCircle} style={{color:`${fc.arColor}`}}/>)
    }
    else if (fc.geometryType == "Line"){
        return (<FontAwesomeIcon icon={faMinus} style={{color:`${fc.arColor}`}}/>)
    }
    
   }

   function getFeatureClass(fc){
    if (fc.featureClassDisplay != null && fc.featureClassDisplay.length >0){
        return fc.featureClassDisplay
    }
    else {
        return fc.featureClass
    }
    
   }

   function getFeatureHighlightKey(){
    if (featureClassHighlight){
      return featureClassHighlight.featureClass;
    }
    else{
      return null;
    }
   }

   function getLeftPane(){
    var isHorizontal = true;
    var height = props.height - 150;
    if (window.innerHeight > window.innerWidth){
      isHorizontal = false;
      height = (props.height/2) - 200;
    }

    if (featureClass != null){
        return (<ConfigFieldList accessToken={props.accessToken} height={props.height} featureClasses={featureClasses} featureClass={featureClass} clipboard={clipboard} onBackToFeatures={handleBackToFeatures} onSave={handleSaveConfig}></ConfigFieldList>);
    } 
    else {
        return (
          <div>
            <Row>
                <Col>
                <Navbar bg="light" variant="light">
                    <Nav onSelect={handleBackToJobTypes} defaultActiveKey="backToJobTypes" className="me-auto">
                        <Nav.Link eventKey="backToJobs"><FontAwesomeIcon icon={faChevronLeft}/></Nav.Link>
                        <Nav.Link eventKey="backToJobs">{props.jobType.jobName} &nbsp;| &nbsp;Feature Types</Nav.Link>
                    </Nav>
                    <Nav onSelect={handleFeatureClassTask}>
                        <Nav.Link eventKey="import"><FontAwesomeIcon icon={faFileImport} />&nbsp;&nbsp;</Nav.Link>
                        <Nav.Link eventKey="update"><FontAwesomeIcon icon={faLink} />&nbsp;&nbsp;</Nav.Link>
                        <Nav.Link eventKey="copy"><FontAwesomeIcon icon={faCopy} />&nbsp;&nbsp;</Nav.Link>
                        <Nav.Link eventKey="add"><FontAwesomeIcon icon={faPlus} />&nbsp;&nbsp;</Nav.Link>
                        <Nav.Link eventKey="delete"><FontAwesomeIcon icon={faTrash} />&nbsp;&nbsp;</Nav.Link>
                    </Nav>
                    </Navbar>
                    <ListGroup defaultActiveKey={getFeatureHighlightKey()} style={{height:`${height}px`,overflow:'auto'}}>
                    {featureClasses.map((fc,i) => (
                    <ListGroup.Item key={fc.featureClass} eventKey={fc.featureClass} active={fc.featureClass == getFeatureHighlightKey()} action className="d-flex justify-content-between align-items-start" onClick={handleHighlight.bind(this,fc,i)}>
                        <Container fluid>
                        <Row>
                        <Col className="d-flex justify-content-between align-items-start">
                          <span>
                            <input type="checkbox" id={fc.featureClass} label="" checked={fc.checked} onChange={handleCheckChanged.bind(this, fc)}/>
                            <span>&nbsp;&nbsp;{getFeatureType(fc)}&nbsp;&nbsp;&nbsp;{getFeatureClass(fc)}&nbsp;&nbsp;</span>
                          </span>
                          <span>
                          <Badge bg="primary" pill>{fc.attributes.length}</Badge>&nbsp;&nbsp;&nbsp;
                          <FontAwesomeIcon icon={faChevronRight} onClick={handleSelect.bind(this,fc,i)}/>
                          </span>
                          
                        </Col>
                        </Row>
                        </Container>
                    </ListGroup.Item>
                    ))}
                    </ListGroup>
                </Col>
                {isHorizontal &&
                  <Col>{getRightPane()}</Col>
                }
            </Row>
            {isHorizontal == false && 
              <Row>
                <Col>{getRightPane()}</Col>
              </Row>}
          </div>
        )
    } 
   
   }

   function getFixedDistanceInfo(){
    var maximumDistance = "";
    var fallbackDistance = "";

    //"fixedDistanceInformation":{"maximumDistance":"", "fallbackDistance":""}
    if (!featureClassHighlight.fixedDistanceInformation){
      //Add the property if it is not there
      featureClassHighlight.fixedDistanceInformation = {}
    }
    
    if (featureClassHighlight.fixedDistanceInformation.maximumDistance){
      maximumDistance = featureClassHighlight.fixedDistanceInformation.maximumDistance;
    }
   
    if (featureClassHighlight.fixedDistanceInformation.fallbackDistance){
      fallbackDistance = featureClassHighlight.fixedDistanceInformation.fallbackDistance;
    }
    
      return (
      <div>
        <Form.Group as={Row} className="mb-2">
            <Form.Label column sm={4}>Maximum Distance</Form.Label>
            <Col sm={8}><Form.Control type="input" name="fixedDistanceInformation.maximumDistance" value={maximumDistance} onChange={fieldUpdate.bind(this)}/></Col>
        </Form.Group>
        <Form.Group as={Row}className="mb-2">
            <Form.Label column sm={4}>Fallback Distance</Form.Label>
            <Col sm={8}><Form.Control type="input" name="fixedDistanceInformation.fallbackDistance" value={fallbackDistance} onChange={fieldUpdate.bind(this)}/></Col>
        </Form.Group>
        </div>
      )
   }

   function getMLModelMapping(){
    var featureClassVal = "";

    if (!featureClassHighlight.mlModelMapping){
      featureClassHighlight.mlModelMapping = {}
    }

    if (featureClassHighlight.mlModelMapping.featureClass){
      featureClassVal = featureClassHighlight.mlModelMapping.featureClass
    }

      return (
      <div>
        <Form.Group as={Row} className="mb-2">
            <Form.Label column sm={4}>Model Feature Class</Form.Label>
            <Col sm={8}><Form.Control type="input" name="mlModelMapping.featureClass" value={featureClassVal} onChange={fieldUpdate.bind(this)}/></Col>
        </Form.Group>
        </div>
      )
   }
   function getModelPicklist(){
    return (props.models.map((m,i) => (
      <option value={m.uuid}>{m.model_name}</option>
      )));
   }

   function getAuxiliaryModelItems(){

    return (featureClassHighlight.auxiliaryModels.map((am,i) => (
      <ListGroup.Item key={i.toString()} className="d-flex justify-content-between align-items-start">
          <Container fluid>
          <Row>
              <Col><Form.Select name="modelId" value={am.modelId} onChange={auxiliaryModelUpdate.bind(this,i)}>
              <option value={""}></option>
                {getModelPicklist()}
              </Form.Select></Col>
              <Col><Form.Select name="modelMode" value={am.modelMode} onChange={auxiliaryModelUpdate.bind(this,i)}>
              <option value={"attributeFromObjectDetection"}>Object Detection</option>
              <option value={"attributeFromObjectCharacterDetection"}>Character Detection</option>
              </Form.Select></Col>
              <Col xs={1}><FontAwesomeIcon icon={faCircleMinus} size='md' onClick={handleDeleteAuxiliaryModel.bind(this, i)}/></Col>
          </Row>
          </Container>
      </ListGroup.Item>
      )));
   }

  function getAuxiliaryModel(){
   
    if (!featureClassHighlight.auxiliaryModels){
      featureClassHighlight.auxiliaryModels = [];
    }

    if (featureClassHighlight.auxiliaryModel && featureClassHighlight.auxiliaryModel.modelId){
      if (featureClassHighlight.auxiliaryModels.length == 0) {
        featureClassHighlight.auxiliaryModels.push(featureClassHighlight.auxiliaryModel)
        delete featureClassHighlight.auxiliaryModel 
      }
    }

    if (featureClassHighlight.auxiliaryModels.length == 0){
      return (
        <ListGroup style={{height:`50px`, overflow:'auto'}}>
            <ListGroup.Item className="d-flex justify-content-between">
              <Container fluid>
                <Row>
                    <Col className="text-center"><FontAwesomeIcon icon={faCirclePlus} size='md' onClick={handleAddAuxiliaryModel.bind(this)}/></Col>
                </Row>
              </Container>
          </ListGroup.Item>
      </ListGroup>
      );
    } else if (featureClassHighlight.auxiliaryModels.length <= 2){
      return (
        <ListGroup style={{height:`150px`, overflow:'auto'}}>
            {getAuxiliaryModelItems()}
            <ListGroup.Item className="d-flex justify-content-between">
              <Container fluid>
                <Row>
                    <Col className="text-center"><FontAwesomeIcon icon={faCirclePlus} size='md' onClick={handleAddAuxiliaryModel.bind(this)}/></Col>
                </Row>
              </Container>
          </ListGroup.Item>
      </ListGroup>
      );
    }
    else{
      return (
        <div>
        <ListGroup style={{height:`160px`, overflow:'auto'}}>
            {getAuxiliaryModelItems()}
        </ListGroup>
        <ListGroup style={{height:`50px`}}>
            <ListGroup.Item className="d-flex justify-content-between">
              <Container fluid>
                <Row>
                    <Col className="text-center"><FontAwesomeIcon icon={faCirclePlus} size='md' onClick={handleAddAuxiliaryModel.bind(this)}/></Col>
                </Row>
              </Container>
          </ListGroup.Item>
      </ListGroup>
      </div>
      );
    }
  }


  function getModelSubtypeItems(){

    return (featureClassHighlight.modelSubtypes.map((ms,i) => (
      <ListGroup.Item key={i.toString()} className="d-flex justify-content-between align-items-start">
          <Container fluid>
          <Row>
              <Col><Form.Control type="input" name="name_" value={ms.name} placeholder="Object" onChange={modelSubtypeUpdate.bind(this, i)}/></Col>
              <Col><Form.Control type="input" name={ms.name} value={ms.value} placeholder="Attribute=<value>" onChange={modelSubtypeUpdate.bind(this, i)}/></Col>
              <Col xs={1}><FontAwesomeIcon icon={faCircleMinus} size='md' onClick={handleDeleteModelSubtype.bind(this, i)}/></Col>
          </Row>
          </Container>
      </ListGroup.Item>
      )));
   }

   function getModelSubtypes(){
    
    if (!featureClassHighlight.modelSubtypes){
      featureClassHighlight.modelSubtypes = []
    }

    if (featureClassHighlight.modelSubtypes){
      if (featureClassHighlight.modelSubtypes.length == 0){
        return (
          <ListGroup style={{height:`50px`, overflow:'auto'}}>
              <ListGroup.Item className="d-flex justify-content-between">
                <Container fluid>
                  <Row>
                      <Col className="text-center"><FontAwesomeIcon icon={faCirclePlus} size='md' onClick={handleAddModelSubtype.bind(this)}/></Col>
                  </Row>
                </Container>
            </ListGroup.Item>
        </ListGroup>
        );
      }
      else if (featureClassHighlight.modelSubtypes.length <= 2){
        return (
          <ListGroup style={{height:`150px`, overflow:'auto'}}>
              {getModelSubtypeItems()}
              <ListGroup.Item className="d-flex justify-content-between">
                <Container fluid>
                  <Row>
                      <Col className="text-center"><FontAwesomeIcon icon={faCirclePlus} size='md' onClick={handleAddModelSubtype.bind(this)}/></Col>
                  </Row>
                </Container>
            </ListGroup.Item>
        </ListGroup>
        );
      }
      else{
        return (
          <div>
          <ListGroup style={{height:`160px`, overflow:'auto'}}>
              {getModelSubtypeItems()}
          </ListGroup>
          <ListGroup style={{height:`50px`}}>
              <ListGroup.Item className="d-flex justify-content-between">
                <Container fluid>
                  <Row>
                      <Col className="text-center"><FontAwesomeIcon icon={faCirclePlus} size='md' onClick={handleAddModelSubtype.bind(this)}/></Col>
                  </Row>
                </Container>
            </ListGroup.Item>
        </ListGroup>
        </div>
        );
      }
    }
    
   }

   function getMessagePlaceholder(){
    return (
      <div style={{position:"absolute", top:2, left:0, zIndex:999}}>
        <Toast show={message != null} >
          <Toast.Body>{message}</Toast.Body>
        </Toast>
      </div>
    )
  }

   function getRightPane(){
    if (featureClassHighlight){
      if (!featureClassHighlight.featureClassDisplay){
        featureClassHighlight.featureClassDisplay = ""
      }

      if (!featureClassHighlight.featureDescriptionSuffix){
        featureClassHighlight.featureDescriptionSuffix = ""
      }

      if (!featureClassHighlight.featureIdAttribute){
        featureClassHighlight.featureIdAttribute = ""
      }

      if (!featureClassHighlight.externalLink){
        featureClassHighlight.externalLink = ""
      }

      if (!featureClassHighlight.autoPlacement){
        featureClassHighlight.autoPlacement = false
      }

      return (
        <Container fluid>
        <Navbar bg="light" variant="light">
          <Navbar.Brand>
              </Navbar.Brand>
              <Navbar.Collapse>
              <Nav>
                  <Nav.Link as={Link} to={'#vision-configuration#feature-type-details'} target="new" eventKey="info"><FontAwesomeIcon icon={faCircleInfo} size="lg"/>&nbsp;&nbsp;</Nav.Link>
                </Nav>
                <Nav className="me-auto">
                </Nav>
                <Nav onSelect={handleFeatureClassTask}>
                  <Nav.Link eventKey="save"><FontAwesomeIcon icon={faFloppyDisk} size="lg"/>&nbsp;&nbsp;</Nav.Link>
                </Nav>
          </Navbar.Collapse>
        </Navbar>
        <Form className='form' style={{height:`${props.height - 130}px`}}>
            <Form.Group as={Row} className="mb-2">
            <Form.Label column sm={4}>Feature Type</Form.Label>
            <Col sm={8}><Form.Control type="input" name="featureClass" value={featureClassHighlight.featureClass} onChange={fieldUpdate.bind(this)}/></Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-2">
            <Form.Label column sm={4}>Display Name</Form.Label>
            <Col sm={8}><Form.Control type="input" name="featureClassDisplay" value={featureClassHighlight.featureClassDisplay} onChange={fieldUpdate.bind(this)}/></Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-2">
            <Form.Label column sm={4}>Geometry Type</Form.Label>
            <Col sm={8}>
              <Form.Select name="geometryType" value={featureClassHighlight.geometryType} onChange={fieldUpdate.bind(this)}>
                <option value="Point">Point</option>
                <option value="Line">Line</option>
              </Form.Select>
            </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-2">
            <Form.Label column sm={4}>Suffix</Form.Label>
            <Col sm={8}><Form.Control type="input" name="featureDescriptionSuffix" value={featureClassHighlight.featureDescriptionSuffix} onChange={fieldUpdate.bind(this)}/></Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-2">
            <Form.Label column sm={4}>Feature Id Attribute</Form.Label>
            <Col sm={8}><Form.Control type="input" name="featureIdAttribute" value={featureClassHighlight.featureIdAttribute} onChange={fieldUpdate.bind(this)}/></Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-2">
            <Form.Label column sm={4}>AR Color</Form.Label>
            <Col sm={4}>
              <Form.Control type="color" name="arColor" value={featureClassHighlight.arColor} onChange={fieldUpdate.bind(this)}/>
            </Col>
            <Col sm={4}>
              <Form.Check type="switch" name="autoPlacement" label="Auto Placement" checked={JSON.parse(featureClassHighlight.autoPlacement)} onChange={fieldCheckUpdate.bind(this)} />
            </Col>
            </Form.Group>
            {getFixedDistanceInfo()}
            {getMLModelMapping()}
            <Form.Group as={Row} className="mb-2">
              <Form.Label column sm={4}>Model Subtypes</Form.Label>
              <Col sm={8}>
                {getModelSubtypes()}
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-2">
              <Form.Label column sm={4}>Auxiliary Models</Form.Label>
              <Col sm={8}>
                {getAuxiliaryModel()}
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-2">
            <Form.Label column sm={4}>External Link</Form.Label>
            <Col sm={7}><Form.Control type="input" name="externalLink" value={featureClassHighlight.externalLink} onChange={fieldUpdate.bind(this)}/></Col>
            <Col sm={1}><Button variant="primary" type="button" onClick={handleExternalLink.bind(this)}>...</Button></Col>
            </Form.Group>
            </Form>
            {featureClassHighlight &&
                <ExternalLinkEditor portals={props.portals} jobType={props.jobType} featureClass={featureClassHighlight} url={featureClassHighlight.externalLink} show={showExternalLink} onConfirm={handleExternalLinkConfirm} onClose={handleExternalLinkClose}></ExternalLinkEditor>
            }
        </Container>
      )
    }
    else{
      //Only show the Save button when there is no items
      return (
        <Container fluid>
        <Navbar bg="light" variant="light" expand="lg">
          <Navbar.Brand>
              </Navbar.Brand>
              <Navbar.Collapse>
                <Nav className="me-auto my-2 my-lg-0">
                </Nav>
                <Nav onSelect={handleFeatureClassTask}>
                  <Nav.Link eventKey="save"><FontAwesomeIcon icon={faFloppyDisk} size="lg"/>&nbsp;&nbsp;</Nav.Link>
                </Nav>
          </Navbar.Collapse>
        </Navbar>
        </Container>
      )
    }
   }

  return (
    <div style={{position:"relative"}}>
    <Row>
      <Col>{getLeftPane()}</Col>
    </Row>
    <ConfirmDialog title="Delete" message={dialogMessage} onConfirm={handleDeleteConfirm} onClose={handleDeleteCancel}></ConfirmDialog>
    {showLogin &&
      <EsriServiceDialog mode={esriMode} portals={props.portals} title="Import Esri Feature" show={showLogin} onConfirm={handleEsriLoginConfirm} onClose={handleEsriLoginCancel} onSave={handleSaveConfig}></EsriServiceDialog>
    }
    <CopyBubble onPaste={handlePaste} onClose={handleCopyCancel} clipboard={clipboard} type="featureClass"></CopyBubble>
    {getMessagePlaceholder()}
    </div>
  )  
        
}

export default ConfigFeatureList;
