
import React, { useEffect, useState, useContext } from 'react';
import {Container, Navbar, Nav, ListGroup, Badge, Row, Col, Modal, Button, Form, Toast } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faTrash, faFloppyDisk, faPlus, faCopy, faDownload, faUpload, faCircleMinus, faCirclePlus, faCircleInfo} from '@fortawesome/free-solid-svg-icons';
import { getConfig, updateConfig, createConfig, getModels} from '../../controllers/configController';
import { getHashParam, setHash} from '../../controllers/hashController';
import ConfigFeatureList from './configFeatureList';
import ConfirmDialog from '../controls/confirmDialog';
import CopyBubble from '../controls/copyBubble';
import fileDownload from 'js-file-download';
import { Link } from 'react-router-dom';

function ConfigEditor(props) {
  const [dialogMessage, setDialogMessage] = useState(null);
  const [overwriteMessage, setOverwriteMessage] = useState(null);
  const [message, setMessage] = useState(null);
  const [jobtype, setJobType] = useState(null);
  const [config, setConfig] = useState(null);
  const [configMode, setConfigMode] = useState("jobTypes");
  const [configId, setConfigId] = useState(null);
  const [jobtypeHighlight, setJobTypeHighlight] = useState(null);
  const [jobtypeIndex, setJobTypeIndex] = useState(-1);
  const [jobtypes, setJobTypes] = useState([]); 
  const [clipboard, setClipboard] = useState(props.clipboard);
  const [models, setModels] = useState([]);
  const [fileUpload, setFileUpload] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  const clearChecked = () => {
    //Let's clear checkboxes in all levels
    jobtypes.forEach(function(j){
      delete j.checked;
      if (j.featureDefinitions){
        j.featureDefinitions.forEach(function(fc){
          delete fc.checked;
          fc.attributes.forEach(function(f){
            delete f.checked;
          });
        })
      }
    })
  };

  const handleConfigMode = (key, e) => {
    setConfigMode(key);
  };

  const handleOverwriteConfirm = async (e) => {
    setOverwriteMessage(null);
   
    var result = await updateConfig(props.accessToken, props.superAdminOrgId, config, true);
    if (result.status == 200){
      setConfigId(result.data.uuid);
      config.uuid = result.data.uuid;
      setConfig(config);
      setMessage("Updates saved!");
      setTimeout(function(){
        setMessage(null);
      }, 3000)
    }
    else{
      setMessage("Failed to save: " + result.message);
      setTimeout(function(){
        setMessage(null);
      }, 3000)
    }
  }

  const validateData = () => {
    //first make sure there is no empty job types
    for (var i=jobtypes.length-1;i>=0;i--){
      var jobtype = jobtypes[i];
      if (!jobtype.featureDefinitions){
        jobtype.featureDefinitions = [];
      }
      if (jobtype.jobName.length == 0){
        jobtypes.splice(i, 1);
        if (jobtypes.length > 0){
          setJobTypeHighlight(jobtypes[0]);
          setJobTypeIndex(0);
        }
      }
      else{
        if (jobtype.modelConfigurationURL == ""){
          delete jobtype.modelConfigurationURL;
        }
  
        if (jobtype.model == ""){
          delete jobtype.model;
        }
      }
      if (jobtype.externalLink == ""){
        delete jobtype.externalLink;
      }
      if (jobtype.mapLayers != null && jobtype.mapLayers.length > 0){
        for (var i=jobtype.mapLayers.length-1;i>=0;i--){
          var mapLayer = jobtype.mapLayers[i];
          if (mapLayer.length == 0){
            jobtype.mapLayers.splice(i, 1);
          }
        }
      }
      
      if (jobtype.mapLayers == null || (jobtype.mapLayers != null && jobtype.mapLayers.length == 0)){
        delete jobtype.mapLayers;
      }

      if (jobtype.featureDefinitions && jobtype.featureDefinitions.length > 0){
        jobtype.featureDefinitions.forEach(function(fc){
          fc.attributes.forEach(function(field){
            if (field.patterns){
              field.patterns.forEach(function(pattern){
                if ((pattern.positiveValidationStrings && pattern.positiveValidationStrings.length == 0) || pattern.positiveValidationStrings == null){
                  delete pattern.positiveValidationStrings
                }
                if ((pattern.negativeValidationStrings && pattern.negativeValidationStrings.length == 0) || pattern.negativeValidationStrings == null){
                  delete pattern.negativeValidationStrings
                }
              });
            }
  
            if (field.subtypePatterns){
              field.subtypePatterns.forEach(function(pattern){
                if ((pattern.positiveValidationStrings && pattern.positiveValidationStrings.length == 0) || pattern.positiveValidationStrings == null){
                  delete pattern.positiveValidationStrings
                }
                if ((pattern.negativeValidationStrings && pattern.negativeValidationStrings.length == 0) || pattern.negativeValidationStrings == null){
                  delete pattern.negativeValidationStrings
                }
              });
            }
          })
        })
      }
      
    }
  }

  const handleSaveConfig = async () => {
    clearChecked();
    validateData();

    config.jobTypes = jobtypes;
    
    var result;
    if (configId){
      result = await updateConfig(props.accessToken, props.superAdminOrgId, config);
    }
    else{
      result = await createConfig(props.accessToken, props.superAdminOrgId, config);
    }
    
    if (result == "serverchanged"){
      //server has updates, prompt user if they want to overwrite
      setOverwriteMessage("A newer version of the config exists on the server, do you want to overwrite?")
    }
    else if (result == "noupdates"){
      setMessage("No updates!");
      setTimeout(function(){
        setMessage(null);
      }, 3000)
    }
    else if (result.status == 200){
      setConfigId(result.data.uuid);
      config.uuid = result.data.uuid;
      setConfig(config);
      setMessage("Updates saved!");
      setTimeout(function(){
        setMessage(null);
      }, 3000)
    }
    else{
      setMessage("Failed to save: " + result.response.data.message);
      setTimeout(function(){
        setMessage(null);
      }, 3000)
    }
  };

  const handleBackToJobTypes = (e) => {
    setJobType(null);
    setHash(null,null,1);
  };

  const handleCheckChanged = (job, e) => {
    var clonedJobTypes = [...jobtypes]//Clone the array before update, so that we get proper state change
    var selectedJobType = clonedJobTypes.filter(function(j){
      return j.jobName == job.jobName;
    })[0];
    if (selectedJobType){
      selectedJobType.checked = e.currentTarget.checked;
      setJobTypes(clonedJobTypes);
    }
  };

  const handleHighlight = (jobtype, i, e) => {
    setJobTypeHighlight(jobtype);
    setJobTypeIndex(i);
  };

  const handleSelect = (jobtype, i, e) => {
    setJobTypeHighlight(jobtype);
    setJobTypeIndex(i);
    setJobType(jobtype);
    setHash(jobtype, "jobName", 1)
  };

  const handleDeleteConfirm = (e) => {
    setDialogMessage(null);
   
    //delete jobs from UI
    var leftJobTypes = jobtypes.filter(function(j){
      return !j.checked
    })
    setJobTypes([...leftJobTypes]);
   
    //Check to see if currently selected field is still in the list
    var currentJobTypes = leftJobTypes.filter(function(j){
      return j.jobName == jobtypeHighlight.jobName;
    })
    if (currentJobTypes.length == 0){
      if (leftJobTypes.length > 0){
        setJobTypeHighlight(leftJobTypes[0]);
        setJobTypeIndex(0);
      }
      else{
        setJobTypeHighlight(null);
        setJobTypeIndex(0);
      }
    }
  }

  const handleOverwriteCancel = (e) => {
    setOverwriteMessage(null);
  }

  const handleDeleteCancel = (e) => {
    setDialogMessage(null);
  }

  const handleAdd = () => {
   //Add empty jobtype
   var jobtype = {
    "jobName":"",
    "modelConfigurationURL":"",
    "featureDefinitions":[]
    }
    var newJobTypes = [...jobtypes]; //clone to update
    newJobTypes.push(jobtype);

    setJobTypeHighlight(jobtype);
    setJobTypeIndex(newJobTypes.length-1);
    setJobTypes(newJobTypes);
  }

  const handleDelete = () => {
    //delete jobs
    var selectedJobTypes = jobtypes.filter(function(j){
      return j.checked;
    })
   
    if (selectedJobTypes.length > 0){
      var deleteMsg;
    
      if (selectedJobTypes.length == 1){
        deleteMsg = "Delete " + selectedJobTypes[0].jobName + "?";
      }
      else{
        deleteMsg = "Delete " + selectedJobTypes.length + " job types?";
      }
      setDialogMessage(deleteMsg);
    }
  }

  const handleCopy = () => {
    var selectedJobTypes = jobtypes.filter(function(j){
      return j.checked;
    })
   
    if (selectedJobTypes.length > 0){
      //Now copy the selected items
      var copyMsg;
      if (selectedJobTypes.length == 1){
        copyMsg = selectedJobTypes[0].jobName + " copied!";
      }
      else{
        copyMsg = selectedJobTypes.length + " job types copied!";
      }

      props.clipboard[0] = {message:copyMsg, data:selectedJobTypes, type:"jobType"}; //This is the global clipboard
      setClipboard([{message:copyMsg, data:selectedJobTypes, type:"jobType"}]);
    }
  }

  const handlePaste = (clipboard) => {
    //Paste copied data
    
    var copiedItems = JSON.parse(JSON.stringify(clipboard[0].data)); //deep copy

    copiedItems.forEach(function(item){
      //check counts of item job name
      var existing = jobtypes.filter(function(j){
        return j.jobName.indexOf(item.jobName) >= 0;
      })

      if (existing.length > 0){
        item.jobName = item.jobName + " " + (existing.length.toString());
      }
      
      item.checked = false;
    });

    var newJobTypes = jobtypes.concat(copiedItems); 
    
    setJobTypes(newJobTypes);
    props.clipboard[0] = null;
    setClipboard([]);

    //Select paste item if it is the first one
    if (newJobTypes.length == 1){
      handleHighlight(newJobTypes[0],0);
    }
  }

  const handleCopyCancel = (clipboard) => {
   setClipboard([]);
  }

  const handleDownload = () => {
    var selectedJobTypes = jobtypes.filter(function(j){
      return j.checked;
    })
   
    if (selectedJobTypes.length > 0){
      fileDownload(JSON.stringify(selectedJobTypes), selectedJobTypes[0].jobName + ".json");
    }
  }

  const handleUpload = () => {
    //Upload config
    //show a modal dialog with file input
    setFileUpload(true);
  }

  const handleFileInput = async (e) => {
    var file = e.target.files[0];
    setSelectedFile(file);
  };

  const handleFileUpload = async (key, e) => {
    setFileUpload(false);
    setMessage("Uploading, please wait...");
    const reader = new FileReader()
    reader.onload = async (e) => { 
      const text = (e.target.result)

      var newJobTypes = JSON.parse(text)
      var newCurrentJobType;
      //Replace existing job types in config, or add new job types
      //check to see if job types exists
      newJobTypes.forEach(function(newJobType){
        newJobType.checked = false

        //Fix model uuid
        var jtModel = models.filter(function(m){
          return newJobType.model == m.model_name;
        });
       
        if (jtModel.length > 0){
          newJobType["modelId"] = jtModel[0].uuid;
        }
        else{
          //Clear out model
          delete newJobType["modelId"]
          newJobType["model"] = ""
        }
       
        //Replace existing
        var isNew = true
        jobtypes.forEach(function(jt,i){
          if (jt.jobName == newJobType.jobName){
            jobtypes[i] = newJobType;
            if (jobtypeHighlight && jobtypeHighlight.jobName == newJobType.jobName){
              newCurrentJobType = newJobType
            }
            isNew = false
          }
        });

         //Add new job types:
         if (isNew == true){
          jobtypes.push(newJobType);
         }
      });

      config.jobTypes = jobtypes
     
      setJobTypes(config.jobTypes)
      if (newCurrentJobType){
        setJobTypeHighlight(newCurrentJobType)
      }
      
      var result;
      if (configId){
        //(accessToken, orgId, config, overwrite)
        result = await updateConfig(props.accessToken, props.superAdminOrgId, config, true);
      }
      else{
        result = await createConfig(props.accessToken, config);
        setConfigId(result.data.uuid);
      }
      
      if (result.status == 200){
        setMessage("Config file uploaded!");
        setTimeout(function(){
          setMessage(null);
        }, 3000)
      }
      else{
        setMessage("Failed to save: " + result.message);
        setTimeout(function(){
          setMessage(null);
        }, 3000)
      }
    };
    reader.readAsText(selectedFile)
    
  };

  const handleFileUploadClose = async (key, e) => {
    setFileUpload(false);
  };

  const handleJobTypeTask = (key, e) => {
    switch(key){
      case "delete":
        handleDelete();
        break;
      
        case "save":
          handleSaveConfig();
          break;

        case "add":
          handleAdd();
          break;
        
        case "copy":
          handleCopy();
          break;

        case "download":
          handleDownload();
          break;
        
        case "upload":
          handleUpload();
          break;

      default:
    }
  };

  useEffect(() => {
    if (props.accessToken){
     function sort_by_key(array, key)
       {
         //Sort desc
         return array.sort(function(a, b)
         {
           var x = a[key]; var y = b[key];
           return ((x < y) ? -1 : ((x > y) ? 1 : 0));
         });
       }
 
       getConfig(props.accessToken, props.superAdminOrgId).then(function(config){
        if (config && config.data != ''){
          var jobtypes
          if (config.data.jobTypes && config.data.jobTypes.length > 0){
            jobtypes = sort_by_key(config.data.jobTypes,"jobName")
          } else {
            jobtypes = config.data.jobTypes
          }
      
          //Get models
          getModels(props.accessToken).then(function(ms){
            setModels(ms);

            //Populate page
            setConfig(config.data);
            setConfigId(config.data.uuid);
            setJobTypes(jobtypes);
            if (jobtypes.length > 0){
             
              //If there is url parameters, select it
              var jobTypeName = getHashParam(window.location.hash, 1);
              if (jobTypeName){
                //find jobType object
                var selectedJobtypeIndex = jobtypes.findIndex(function(jt){
                  return jt.jobName == jobTypeName;
                });
                if (selectedJobtypeIndex >= 0){
                  handleSelect(jobtypes[selectedJobtypeIndex]);
                }
                else{
                  handleHighlight(jobtypes[0],0);
                }
              }
              else{
                handleHighlight(jobtypes[0],0);
              }
            }
          });
        }
        else{
          //we don't have any configuration for the organization yet, let's create an empty one
          setConfig({configurationName:props.org,
                     jobTypes:[]});
          setConfigId(null);
          setJobTypes([]);
          setJobTypeHighlight(null);
          setJobTypeIndex(-1);
          getModels(props.accessToken).then(function(ms){
                      setModels(ms);
                     });
        }
     }); 

    }
       
   },[props.accessToken, props.superAdminOrgId]);

   function modelFieldUpdate(e){
    var currentJT = jobtypes[jobtypeIndex]; //Need to access by jobTypeIndex so that changing Job Type name won't cause issue
    if (e.currentTarget.value != ""){
      //Find the uuid
      var selectedModel = models.filter(function(m){
        return m.model_name == e.currentTarget.value;
      })[0];
     
      currentJT["modelId"] = selectedModel.uuid;
    }
    else{
      if (currentJT["modelId"]){
        delete currentJT["modelId"];
      }
    }
    fieldUpdate(e);
   }

   function fieldUpdate(e){
    //Find current job type in the array to update
     var currentJT = jobtypes[jobtypeIndex]; //Need to access by jobTypeIndex so that changing Job Type name won't cause issue
    
     if (e.currentTarget.value != ""){
        currentJT[e.currentTarget.name] = e.currentTarget.value;
     }
     else{
        if (currentJT[e.currentTarget.name]){
          delete currentJT[e.currentTarget.name]
        }
     }

    //Update current jobtype as well
    if (jobtypeHighlight){
      var newJobType = {...currentJT}; //Clone it so that it will trigger state change
      setJobTypeHighlight(newJobType);
    }

    if (jobtype){
      setJobType(newJobType);
    }
   }

   function fieldCheckUpdate(e){
    //Find current job type in the array to update
    var currentJT = jobtypes[jobtypeIndex]; //Need to access by jobTypeIndex so that changing Job Type name won't cause issue
    
     if (e.currentTarget.value != ""){
        var value = e.currentTarget.checked;
        if (value == true){
          value = true
        }
        else{
          value = false
        }
        currentJT[e.currentTarget.name] = value;
     }

      //Update current jobtype as well
      if (jobtypeHighlight){
        var newJobType = {...currentJT}; //Clone it so that it will trigger state change
        setJobTypeHighlight(newJobType);
      }

      if (jobtype){
        setJobType(newJobType);
      }
    
   }

   function mapLayerUpdate(i, e){
    if (configMode == "jobTypes"){
      //Find current job type in the array to update
      var currentJT = jobtypes[jobtypeIndex]; 

      //Find current pattern to update
      currentJT.mapLayers[i].mapLayer = e.currentTarget.value;
      
      //Update current field as well
      //Update current jobtype as well
      if (jobtypeHighlight){
        var newJobType = {...currentJT}; //Clone it so that it will trigger state change
        setJobTypeHighlight(newJobType);
      }

      if (jobtype){
        setJobType(newJobType);
      }
    }
    else if (configMode == "mapLayers"){
      config.mapLayers[i].mapLayer = e.currentTarget.value;
      var newConfig = {...config}; //Clone it so that it will trigger state change
      setConfig(newConfig);
    }
  }

   const handleDeleteMapLayer = (i, e) => {
   
    if (configMode == "jobTypes"){
      //Find current job type in the array to update
      var currentJT = jobtypes[jobtypeIndex];

      currentJT.mapLayers.splice(i,1);
      
      //Update current jobtype as well
      if (jobtypeHighlight){
        var newJobType = {...currentJT}; //Clone it so that it will trigger state change
        setJobTypeHighlight(newJobType);
      }
      if (jobtype){
        setJobType(newJobType);
      }
    }
    else{
      config.mapLayers.splice(i,1);
      var newConfig = {...config}; //Clone it so that it will trigger state change
      setConfig(newConfig);
    }
    
  };

  const handleAddMapLayer = (e) => {
    if (configMode == "jobTypes"){
      //Find current job type in the array to update
      var currentJT = jobtypes[jobtypeIndex];

      if (currentJT.mapLayers == null){
        currentJT.mapLayers = [];
      }
      currentJT.mapLayers.push({mapLayer:""});
      
      //Update current field as well
      if (jobtypeHighlight){
        var newJobType = {...currentJT}; //Clone it so that it will trigger state change
        setJobTypeHighlight(newJobType);
      }
      if (jobtype){
        setJobType(newJobType);
      }
    }
    else if (configMode == "mapLayers"){
      if (config.mapLayers == null){
        config.mapLayers = [];
      }
      config.mapLayers.push({mapLayer:""});
      var newConfig = {...config}; //Clone it so that it will trigger state change
      setConfig(newConfig);
    }
  };

   function getModelPicklist(){
    return (models.map((m,i) => (
      <option value={m.model_name}>{m.model_name}</option>
      )));
   }

   function getJobModePicklist(){
    var jobModes = ["Standard", "Linear", "Non AR"];
    return (jobModes.map((m,i) => (
      <option value={m}>{m}</option>
      )));
   }

   function getModelName(){
    if (jobtypeHighlight.model){
      return jobtypeHighlight.model;
    }
    else if (jobtypeHighlight.modelId){
      var selectedModel = models.filter(function(m){
        return m.uuid == jobtypeHighlight.modelId;
      })[0];

      if (selectedModel){
        return selectedModel.model_name;
      }
      else{
        return "";
      }
      
    }
    else{

      return "";
    }
   }

   function getMapLayerItems(){
      if (configMode == "jobTypes"){
        return (jobtypeHighlight.mapLayers.map((l,i) => (
          getMapLayerItem(l,i)
        )))
      }
      else if (configMode == "mapLayers" && config.mapLayers != null){
        return (config.mapLayers.map((l,i) => (
          getMapLayerItem(l,i)
        )))
      }
   }

   function getMapLayerItem(l,i){
    return (
      <ListGroup.Item key={i.toString()} className="d-flex justify-content-between align-items-start">
            <Container fluid>
            <Row>
                <Col><Form.Control type="input" value={l.mapLayer} onChange={mapLayerUpdate.bind(this,i)}/></Col>
                <Col xs={1}><FontAwesomeIcon icon={faCircleMinus} size='md' onClick={handleDeleteMapLayer.bind(this, i)}/></Col>
            </Row>
            </Container>
        </ListGroup.Item>
    )
   }

   function getMapLayers(){
    //Regex validation control:
    //https://github.com/pxpeterxu/react-regexr
    //https://regexr.com/
    if (jobtypeHighlight && !jobtypeHighlight.mapLayers){
      jobtypeHighlight.mapLayers = []
    }
    if (jobtypeHighlight && jobtypeHighlight.mapLayers.length <= 2){
      return (
        <ListGroup style={{height:`165px`, overflow:'auto'}}>
            {getMapLayerItems()}
            <ListGroup.Item className="d-flex justify-content-between">
              <Container fluid>
                <Row>
                    <Col className="text-center"><FontAwesomeIcon icon={faCirclePlus} size='md' onClick={handleAddMapLayer.bind(this)}/></Col>
                </Row>
              </Container>
          </ListGroup.Item>
        </ListGroup>
      )
    }
    else{
      return (
        <div>
        <ListGroup style={{height:`165px`, overflow:'auto'}}>
            {getMapLayerItems()}
            </ListGroup>
            <ListGroup style={{height:`50px`}}>
            <ListGroup.Item className="d-flex justify-content-between">
              <Container fluid>
                <Row>
                    <Col className="text-center"><FontAwesomeIcon icon={faCirclePlus} size='md' onClick={handleAddMapLayer.bind(this)}/></Col>
                </Row>
              </Container>
          </ListGroup.Item>
        </ListGroup>
        </div>
      )
    }
   }

   function getRightPane(){
    if (jobtypeHighlight){
      if (!jobtypeHighlight.externalLink){
        jobtypeHighlight.externalLink = ""
      }
      if (!jobtypeHighlight.testJob){
        jobtypeHighlight.testJob = false
      }
      if (!jobtypeHighlight.jobMode){
        jobtypeHighlight.jobMode = "Standard"
      }

      if (!jobtypeHighlight.defaultFeature){
        jobtypeHighlight.defaultFeature = ""
      }

      if (!jobtypeHighlight.linearFeatureClass){
        jobtypeHighlight.linearFeatureClass = ""
      }

      return (
        <Container fluid>
        <Navbar bg="light" variant="light">
          <Navbar.Brand>
              </Navbar.Brand>
                <Nav>
                  <Nav.Link as={Link} to={'#vision-configuration'} target="new" eventKey="info"><FontAwesomeIcon icon={faCircleInfo} size="lg"/>&nbsp;&nbsp;</Nav.Link>
                </Nav>
                <Nav className="me-auto">
                </Nav>
                <Nav onSelect={handleJobTypeTask}>
                  <Nav.Link eventKey="save"><FontAwesomeIcon icon={faFloppyDisk} size="lg"/>&nbsp;&nbsp;</Nav.Link>
                </Nav>
        </Navbar>
        <Form className='form' style={{height:`${props.height - 130}px`}}>
          <Form.Group as={Row} className="mb-2">
            <Form.Label column sm={4}>Name</Form.Label>
            <Col sm={8}><Form.Control type="input" name="jobName" value={jobtypeHighlight.jobName} onChange={fieldUpdate.bind(this)}/></Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-2">
            <Form.Label column sm={4}>Test Job</Form.Label>
            <Col sm={8}><Form.Check type="switch" name="testJob" checked={JSON.parse(jobtypeHighlight.testJob)} onChange={fieldCheckUpdate.bind(this)} /></Col>
          </Form.Group>
          <Form.Group as={Row}className="mb-2">
            <Form.Label column sm={4}>Job Mode</Form.Label>
            <Col sm={8}>
              <Form.Select name="jobMode" value={jobtypeHighlight.jobMode} onChange={fieldUpdate.bind(this)}>
              <option value={""}></option>
                {getJobModePicklist()}
              </Form.Select>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-2">
            <Form.Label column sm={4}>Linear Feature Class</Form.Label>
            <Col sm={8}><Form.Control type="input" name="linearFeatureClass" value={jobtypeHighlight.linearFeatureClass} onChange={fieldUpdate.bind(this)}/></Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-2">
            <Form.Label column sm={4}>Default Feature</Form.Label>
            <Col sm={8}><Form.Control type="input" name="defaultFeature" value={jobtypeHighlight.defaultFeature} onChange={fieldUpdate.bind(this)}/></Col>
          </Form.Group>
          <Form.Group as={Row}className="mb-2">
            <Form.Label column sm={4}>Model</Form.Label>
            <Col sm={8}>
              <Form.Select name="model" value={getModelName()} onChange={modelFieldUpdate.bind(this)}>
              <option value={""}>Select a model</option>
                {getModelPicklist()}
              </Form.Select>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-2">
            <Form.Label column sm={4}>External Link</Form.Label>
            <Col sm={8}><Form.Control type="input" name="externalLink" value={jobtypeHighlight.externalLink} onChange={fieldUpdate.bind(this)}/></Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-2">
          <Form.Label column sm={4}>Map Layers</Form.Label>
          <Col sm={8}>
                {getMapLayers()}
          </Col>
        </Form.Group>
        </Form>
        </Container>
      )
    }
    else{
      //Only show the save button when no items
      return (
        <Container fluid>
        <Navbar bg="light" variant="light" expand="lg">
          <Navbar.Brand>
              </Navbar.Brand>
                <Nav className="me-auto my-2 my-lg-0">
                </Nav>
                <Nav onSelect={handleJobTypeTask}>
                  <Nav.Link eventKey="save"><FontAwesomeIcon icon={faFloppyDisk} size="lg"/>&nbsp;&nbsp;</Nav.Link>
                </Nav>
        </Navbar>
        </Container>
      )
    }
   }

   function getJobTypeHighlightKey(){
    if (jobtypeHighlight){
      return jobtypeHighlight.jobName;
    }
    else{
      return null;
    }
   }

   function getFileUploadDialog(){
      return (
        <Modal show={fileUpload}>
          <Modal.Header closeButton>
            <Modal.Title>Select a config file to upload</Modal.Title>
          </Modal.Header>
          <Modal.Body><input type="file" accept='.json' onChange={handleFileInput}/></Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleFileUploadClose}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handleFileUpload}>
              Upload
            </Button>
          </Modal.Footer>
        </Modal>
      )
    }

   function getFeatureCount(jobtype){
      var featureCount = 0;
      if (jobtype && jobtype.featureDefinitions){
        featureCount = jobtype.featureDefinitions.length
      }
      return featureCount
   }

   function getLeftPane(){
    var isHorizontal = true;
    var height = props.height - 150;
    if (window.innerHeight > window.innerWidth){
      isHorizontal = false;
      height = (props.height/2) - 200;
    }
    
    
    if (jobtype){
      var portals
      if (config && config.portals){
        portals = config.portals;
      } else {
        portals = [];
      }
      return (<ConfigFeatureList accessToken={props.accessToken} portals={portals} height={props.height} jobType={jobtype} models={models} clipboard={clipboard} onBackToJobTypes={handleBackToJobTypes} onSave={handleSaveConfig}></ConfigFeatureList>)
    }
    else{
      return (
        <div>
        <Row>
          <Col>
            <Navbar bg="light" variant="light">
              <Nav onSelect={handleConfigMode} defaultActiveKey={configMode} className="me-auto">
                  <Nav.Link eventKey="jobTypes">Job Types&nbsp;&nbsp;</Nav.Link>
                  <Nav.Link eventKey="mapLayers">Map Layers&nbsp;&nbsp;</Nav.Link>
              </Nav>
              <Nav className="me-auto"></Nav>
              {(configMode == "jobTypes") &&
                <Nav onSelect={handleJobTypeTask}>
                  <Nav.Link eventKey="copy"><FontAwesomeIcon icon={faCopy} />&nbsp;&nbsp;</Nav.Link>
                  <Nav.Link eventKey="add"><FontAwesomeIcon icon={faPlus} />&nbsp;&nbsp;</Nav.Link>
                  <Nav.Link eventKey="delete"><FontAwesomeIcon icon={faTrash} />&nbsp;&nbsp;</Nav.Link>
                  <Nav.Link eventKey="download"><FontAwesomeIcon icon={faDownload} />&nbsp;&nbsp;</Nav.Link>
                  <Nav.Link eventKey="upload"><FontAwesomeIcon icon={faUpload} />&nbsp;&nbsp;</Nav.Link>
                </Nav>
              }

              {(configMode == "mapLayers") &&
                <Nav onSelect={handleJobTypeTask}>
                  <Nav.Link eventKey="save"><FontAwesomeIcon icon={faFloppyDisk} size="lg"/>&nbsp;&nbsp;</Nav.Link>
                </Nav>
              }
          </Navbar>
          {(configMode == "jobTypes") &&
            <ListGroup defaultActiveKey={getJobTypeHighlightKey()} style={{height:`${height}px`, overflow:'auto'}}>
            {jobtypes.map((jobtype,i) => (
              <ListGroup.Item key={jobtype.jobName} eventKey={jobtype.jobName} active={jobtype.jobName == getJobTypeHighlightKey()} action className="d-flex justify-content-between align-items-start" onClick={handleHighlight.bind(this,jobtype,i)}>
                <Container fluid>
                  <Row>
                    <Col><Form.Check type="checkbox" id={jobtype.jobName} label={jobtype.jobName} checked={jobtype.checked} onChange={handleCheckChanged.bind(this, jobtype)}/></Col>
                    <Col xs={1}><Badge bg="primary" pill>{getFeatureCount(jobtype)}</Badge></Col>
                    <Col xs={1}><FontAwesomeIcon icon={faChevronRight}  size='1x'  onClick={handleSelect.bind(this,jobtype,i)}/></Col>
                  </Row>
                </Container>
              </ListGroup.Item>
            ))}
            </ListGroup>
          }
          {(configMode == "mapLayers") &&
            <Container>
              <Form>
                <Form.Group as={Row} className="mb-2">
                  <Form.Label column sm={4}>Map Layers</Form.Label>
                  <Col sm={8}>
                        {getMapLayers()}
                  </Col>
                </Form.Group>
              </Form>
            </Container>
          }
          </Col>
          {isHorizontal && 
              <Col>
              {(configMode == "jobTypes") &&
                getRightPane()}
              </Col>
          }
      </Row>
      {isHorizontal == false && 
        <Row>
          <Col>
          {(configMode == "jobTypes") &&
            getRightPane()
          }
          </Col>
        </Row>}
        </div>
      )
    }
   }

   function getMessagePlaceholder(){
    return (
      <div style={{position:"absolute", top:2, left:0, zIndex:999}}>
        <Toast show={message != null} >
          <Toast.Body>{message}</Toast.Body>
        </Toast>
      </div>
    )
  }

  return (
    <div style={{position:"relative"}}>
        <Row>
          <Col>{getLeftPane()}</Col>
        </Row>
        <CopyBubble onPaste={handlePaste} onClose={handleCopyCancel} clipboard={clipboard} type="jobType"></CopyBubble>
        <ConfirmDialog title="Delete" message={dialogMessage} onConfirm={handleDeleteConfirm} onClose={handleDeleteCancel}></ConfirmDialog>
        <ConfirmDialog title="Save" message={overwriteMessage} onConfirm={handleOverwriteConfirm} onClose={handleOverwriteCancel}></ConfirmDialog>
        {getFileUploadDialog()}
        {getMessagePlaceholder()}
    </div>
  )  
        
}

export default ConfigEditor;
