
import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Accordion, Form, Button } from "react-bootstrap";
import { JsonViewer } from '@textea/json-viewer'
import Canvas from './canvas';
import { getJobCompare} from '../../controllers/jobController';
function JobCompare(props) {
  const [summaryJson, setSummaryJson] = useState(null);
  const [differencesJson, setDifferencesJson] = useState(null);
  const [job1, setJob1] = useState(null);
  const [job2, setJob2] = useState(null);
  useEffect(_ => {
    var job1 = props.job
    if (props.selectedJobs.length > 0){
      var jobs = props.selectedJobs.filter(function(j){
        return j.uuid != props.job.uuid;
      })

      var job2 = jobs[0]
      getJobCompare(props.accessToken, job1.uuid, job2.uuid).then(function(result){
        let summaryJson = result.data.feature_comparison.summary;
        let differencesJson = result.data.feature_comparison.differences;
        setSummaryJson(summaryJson);
        setDifferencesJson(differencesJson);
        setJob1(job1.name);
        setJob2(job2.name);
      })
    }
  }, [props.job, props.jobs]);

  function getFieldRow(key, value){
      return (
      <Row>
        <Col className="f1">{key}</Col>
        <Col className="f1">{value}</Col>
      </Row>
      )
    
  }

  function getSummary(){
    var rows = []
    for (const key in summaryJson) {
      var summary = {}
      summary.key = key
      summary.value = summaryJson[key]
      rows.push(summary);
    }
    return rows;
  }

  function getDifference(){
    var features = []
    features.push({key:"Feature", different_fields:"different", matched_fields:"matched", total_fields:"total", distance:"distance", status:"status"})
    for (const key in differencesJson) {
      var feature = differencesJson[key]
      feature.key = key
      if (feature.Geometry){
        feature.distance = feature.Geometry.distance.toFixed(4)
      } else {
        feature.distance = " "
      }
      features.push(feature)
    }

    return features.map((f) => (
      (
        <Row>
          <Col className="f1">{f.key}</Col>
          <Col className="f1">{f.different_fields}</Col>
          <Col className="f1">{f.matched_fields}</Col>
          <Col className="f1">{f.total_fields}</Col>
          <Col className="f1">{f.distance}</Col>
          <Col className="f1">{f.status}</Col>
        </Row>
        )
      ))
  }

  function getFeatureSummary(){
    var distances = [];
    for (const key in differencesJson) {
      var feature = differencesJson[key];
      if (feature.Geometry){
        var distance = feature.Geometry.distance;
        distances.push(distance)
      } 
    }

    if (distances.length > 0){
      // Calculate average
      const sum = distances.reduce((a, b) => a + b, 0);
      const avg = (sum / distances.length) || 0;
      const average = avg.toFixed(4);
      // Calculate max
      const max = Math.max(...distances).toFixed(4);
      
      return (
        <Row>
          <Col className="f1">Average Distance</Col>
          <Col className="f1">{average}</Col>
          <Col className="f1">Max Distance</Col>
          <Col className="f1">{max}</Col>
        </Row>
        )
    } else {
      return null
    }
    
  }

  function getPropertiesDifference(){
    
    var features = []
    features.push({key:"Feature", field:"field", source:"source", target:"target"})
    for (const key in differencesJson) {
      if (differencesJson[key].Properties){
        for (const field in differencesJson[key].Properties) {
          var feature = differencesJson[key].Properties[field]
          feature.key = key
          feature.field = field
    
          features.push(feature)
        }
      }
    }

    return features.map((f) => (
      (
        <Row>
          <Col className="f1">{f.key}</Col>
          <Col className="f1">{f.field}</Col>
          <Col className="f1">{f.source}</Col>
          <Col className="f1">{f.target}</Col>
        </Row>
        )
  ))
  }

  return (
    <div>
      <div>{job1} vs {job2}</div>
      <Accordion defaultActiveKey="summary" style={{height:`${props.height - 200}px`, overflow:'auto'}}>
            <Accordion.Item eventKey="summary">
              <Accordion.Header>Summary</Accordion.Header>
              <Accordion.Body>
              <Container fluid>
                {getSummary().map((s) => (
                  getFieldRow(s.key, s.value)
              ))}
              </Container>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="diff">
              <Accordion.Header>Feature Differences</Accordion.Header>
              <Accordion.Body>
              <Container fluid>
                {getDifference()}
              </Container>
              <Container fluid>
                {getFeatureSummary()}
              </Container>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="props">
              <Accordion.Header>Feature Properties Differences</Accordion.Header>
              <Accordion.Body>
              <Container fluid>
                {getPropertiesDifference()}
              </Container>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
    </div>
      
     )
}

export default JobCompare;
