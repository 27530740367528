
import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Container, Navbar, Nav, Accordion, Form, Tabs, Row, Col} from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { JsonViewer } from '@textea/json-viewer'
import ImageOverlay from './imageOverlay';
import jwt_decode from "jwt-decode";

function FeatureDetail(props) {
  
  const [jobFeature, setJobFeature] = useState(props.jobFeature);
  const [timeoutId, setTimeoutId] = useState(0);

  const handleBackToFeatures = (e) => {
    props.onBackToFeatures();
  };

  function getPhotos(featureConfig){
    return (
      <div style={{height:`490px`, overflow:'auto'}}>{props.jobFeature.images.map((p) => (
        <div>
          {p.sidecar_presigned_url != null && p.sidecar_presigned_url.length > 0 &&
          <div>
            <ImageOverlay image={p} type="label" thumbnail={true} canEdit={false} jobFeature={props.jobFeature} config={featureConfig}/>
            <div>&nbsp;</div>
            <ImageOverlay image={p} type="bbox" thumbnail={true} canEdit={false} jobFeature={props.jobFeature} config={featureConfig}/>
          </div>
          }
          {p.sidecar_presigned_url == null &&
             <div className='jobimage'><img src={p.presigned_url}/></div>
          }
          <div>&nbsp;</div>
        </div>
        
        ))}
      </div>
    )
  }

  function fieldUpdate(e){
    if (timeoutId > 0){
      clearTimeout(timeoutId);
    }

    props.jobFeature.properties[e.currentTarget.name] = e.currentTarget.value;

    var updatedJobFeature = {...props.jobFeature}; //Clone it so that it will trigger state change
    setJobFeature(updatedJobFeature);

    var tid = setTimeout(function(){
      props.onPropsUpdate(props.jobFeature);
    }, 1000);

    setTimeoutId(tid);
  }

  function getObservations(featureConfig){
    if (props.jobFeature.observations){
      return (
        <div style={{height:`490px`, overflow:'auto'}}>{props.jobFeature.observations.map((p) => (
          <div>
            {p.sidecar_presigned_url != null && p.sidecar_presigned_url.length > 0 &&
            <div>
              <ImageOverlay image={p} type="bbox" thumbnail={true}  canEdit={false} jobFeature={props.jobFeature} config={featureConfig}/>
            </div>
            }
            {p.sidecar_presigned_url == null &&
               <div className='jobimage'><img src={p.presigned_url}/></div>
            }
            <div>&nbsp;</div>
          </div>
          ))}
        </div>
      )
    }
  }

  function getJson(){
    return (<JsonViewer value={props.jobFeature} />)
    //return (
    //  <div>{JSON.stringify(props.jobFeature)}</div>
    //  )
  }

  function getLatLong(){
    if (props.jobFeature.geometry.type == "Point"){
      return (<Container fluid>
        <Row>
          <Col className="f1">Lat/Long</Col>
          <Col className="f1">{props.jobFeature.geometry.coordinates[1] + "," + props.jobFeature.geometry.coordinates[0]}</Col>
        </Row>
      </Container>)
    }
    
  }

  function getValue(value, otherValue){
    if (value != null && otherValue != null && otherValue != ""){
      if (value.toLowerCase() == otherValue.toLowerCase()){
        return (<span style={{ color: 'green' }}>{value}</span>);
      } else {
        return (<span style={{ color: 'blue' }}>{value}</span>);
      }
    } else if (value == null && otherValue != null ){
      return (<span style={{ color: 'grey' }}>{otherValue}</span>);
    } else {
      return (<span>{value}</span>);
    }
  }

  function getOtherValue(value, otherValue){
    if (value != null && otherValue != null && otherValue != ""){
      if (value.toLowerCase() == otherValue.toLowerCase()){
        return ("");
      } else if (value == null && otherValue != null ){
        return ("");
      } else {
        return (<span style={{ color: 'grey' }}> {otherValue}</span>);
      }
    } else {
      return ("");
    }
  }

  function getName(name, value, otherValue){
    if (value == null && otherValue != null ){
      return (<span style={{ color: 'grey' }}>{name}</span>);
    } else {
      return (name)
    }
  }

  function getAttributes(featureConfig){
    var decoded = jwt_decode(props.accessToken);
    var roles = decoded["https://sspvision.net/roles"];
    var isSuperAdmin = (roles.indexOf("vision-superadmin") >= 0);

    var fields=[];
      
      if (featureConfig){
        for (const key in props.jobFeature.properties) {
          if (key != "ObjectID" && key != "image_names" && key != "FeatureClass" && key != "observation_names"){
            //Lookup field display name
            var displayName = key
            if (displayName == "ssp_id"){
              displayName = "SSP ID";
            }else if(displayName == "ssp_description"){
              displayName = "SSP Description";
            }else if(displayName == "added_by"){
              displayName = "Added By";
            }else if(displayName == "timestamp"){
              displayName = "Timestamp";
            }else if(displayName == "uuid"){
              displayName = "UUID";
            }else if(displayName == "from_feature"){
              displayName = "From Feature";
            }else if(displayName == "from_uuid"){
              displayName = "From Feature ID";
            }else if(displayName == "to_feature"){
              displayName = "To Feature";
            }else if(displayName == "to_uuid"){
              displayName = "To Feature ID";
            }else if(displayName == "moved"){
              displayName = "Moved";
            }
  
            if (featureConfig != null){
              var field = featureConfig.attributes.filter(function(f){
                return f.name == key
              })[0];
  
              if (field){
                if (field.displayName && field.displayName.length > 0){
                  displayName = field.displayName;
                }
              }
            }
            
            //Check to see if there is another feature to compare:
            var otherValue = "";
            if (props.selectedFeatures.length == 2){
              var otherFeature = props.selectedFeatures.filter(function(f){
                return f.properties.uuid != props.jobFeature.properties.uuid;
              })[0];
              if (otherFeature && otherFeature.properties){
                otherValue = otherFeature.properties[key];
              }
            }

            fields.push({name: displayName, value: props.jobFeature.properties[key], other:otherValue, key:key});
          }
        }

        //Also add the fields that are in other feature but not in current feature
        if (props.selectedFeatures.length == 2){
          var otherFeature = props.selectedFeatures.filter(function(f){
            return f.properties.uuid != props.jobFeature.properties.uuid;
          })[0];
          var otherFeatureConfig = getFeatureConfig(otherFeature)
          
          for (const key in otherFeature.properties) {
            var displayName = key

            if (otherFeatureConfig != null){
              var field = otherFeatureConfig.attributes.filter(function(f){
                return f.name == key
              })[0];
    
              if (field){
                if (field.displayName && field.displayName.length > 0){
                  displayName = field.displayName;
                }
              }
            }

            if (props.jobFeature.properties[key]== null){
              fields.push({name: displayName, value:null, other:otherFeature.properties[key]});
            }
          }
        }
      }
      else{
        for (const key in props.jobFeature.properties) {
          fields.push({name: key, value: props.jobFeature.properties[key]});
        }
      }
    return (
      fields.map((field) => (
        <Container fluid>
          {((field.name == "SSP ID" || field.name == "SSP Description") && isSuperAdmin) &&
            <Row>
              <Col className="f1">{getName(field.name, field.value, field.other)}</Col>
              <Col className="f1"><Form.Control style={{height:`25px`}} type="input" name={field.key} value={field.value} onChange={fieldUpdate.bind(this)}/></Col>
            </Row>
          }
          {!((field.name == "SSP ID" || field.name == "SSP Description") && isSuperAdmin) &&
            <Row>
              <Col className="f1">{getName(field.name, field.value, field.other)}</Col>
              <Col className="f1">{getValue(field.value, field.other)}{getOtherValue(field.value, field.other)}</Col>
            </Row>
          }
        </Container>
      ))
    )
  }
   function getFeatureConfig(feature){
    //Find feature config
    var featureConfigs = props.config.jobTypes.filter(function(jt){
      return jt.jobName == props.job.job_type;
    });

    if (featureConfigs.length > 0){
      var featureConfig = featureConfigs[0].featureDefinitions.filter(function(fc){
        return fc.featureClass == feature.properties.FeatureClass || fc.featureClassDisplay == feature.properties.FeatureClass
      })[0];
      return featureConfig
    }
    return null
   }
    function getID(feature){
        var featureConfig = getFeatureConfig(feature)

        if (featureConfig != null){
          var featureClassDisplay = featureConfig.featureClass
          if (featureConfig.featureClassDisplay != null && featureConfig.featureClassDisplay.length >0){
            featureClassDisplay = featureConfig.featureClassDisplay
          }
          if (feature.properties["ssp_description"] != featureClassDisplay){
            return feature.properties["ssp_description"]
          }
          else{
            return feature.properties["ssp_id"].replace(featureConfig.featureClass, featureClassDisplay);
          }
        }
        else{
          return feature.properties["ssp_id"]
        }
    }

    if (props.jobFeature){
      //Find feature config
      var featureConfig = getFeatureConfig(props.jobFeature)
    
      return (
        <div>
          <Navbar bg="light" variant="light" expand="lg" fill>
              <Nav onSelect={handleBackToFeatures} defaultActiveKey="backToFeatures" className="me-auto">
                <Nav.Link eventKey="backToFeatures"><div className="f2">&nbsp;&nbsp;<FontAwesomeIcon icon={faChevronLeft} size="xs"/>&nbsp;&nbsp;{getID(props.jobFeature)}</div></Nav.Link>
              </Nav>
          </Navbar>
          <Accordion defaultActiveKey={["data","photo"]} alwaysOpen style={{height:`${props.height - 260}px`, overflow:'auto'}}>
            <Accordion.Item eventKey="data">
              <Accordion.Header>Data</Accordion.Header>
              <Accordion.Body>
              {getAttributes(featureConfig)}
              {getLatLong()}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="photo">
              <Accordion.Header>Photos</Accordion.Header>
              <Accordion.Body>
              {getPhotos(featureConfig)}
              </Accordion.Body>
            </Accordion.Item>
            {(props.jobFeature.observations!=null) &&
              <Accordion.Item eventKey="observation">
              <Accordion.Header>Observations</Accordion.Header>
              <Accordion.Body>
              {getObservations(featureConfig)}
              </Accordion.Body>
            </Accordion.Item>
            }
            <Accordion.Item eventKey="json">
              <Accordion.Header>JSON</Accordion.Header>
              <Accordion.Body>
               {getJson()}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
         
        </div>
        
      )
    
    }
    else{
      return <div>No feature selected</div>
    }
  
}

export default FeatureDetail;
